import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';
import TextField from '../../../component/form/TextField';
import TimeSelect from '../../../component/form/TimeSelect';
import PrimaryButton, { AltButton } from '../../../component/form/PrimaryButton';
import OptionalTextField from '../../../component/form/OptionalTextField';
import StaffAllocationTable from '../../../component/form/StaffAllocationTable';
import Select from '../../../component/form/Select';
import AddressBookAutocomplete, { getLabelWithSuburb } from '../../../component/form/AddressBookAutocomplete';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import ContactCard from '../../contacts/ContactCard';
import ColumnLayout from '../../../component/ColumnLayout';
import { ADDRESS_BOOK_CATEGORY } from '../../funeral/funeralConstants';
import {
    DISPOSAL_METHOD_OPTIONS,
    GRAVE_DEPTH_OPTIONS,
    GROUND_TYPE_OPTIONS,
    PLACE_OF_SERVICE_TYPE_OPTIONS,
    PLACE_OF_VIEWING_TYPE_OPTIONS
} from './DetailsConstants';
import RadioGroup from '../../../component/form/RadioGroup';
import { ImageList, ImageListItem } from '../../../component/form/ImageList';
import { Hidden, IconButton } from '@material-ui/core';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import { dateTimeToString } from '../../../util/date';
import { getArray } from '../../../util/objects';
import { isContactDefined } from '../../../util/bookable';
import { PreloadProducts, ProductConfig, ProductMetadata } from '../../../util/products';
import ProductSelectionModal from '../../../component/product-modal/ProductSelectionModal';
import { TabTypes } from '../../../component/product-modal/ProductModalConstants';
import DisplayContact from '../../../component/bookings/DisplayContact';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';

class Details extends Component {
    state = {
        placeOfCommittal: undefined,
        placeOfViewingRequired: false,
        reflectionRoomRequired: false,
        clergyRequired: false,
        popupValuablesOpen: false,
        popupAddValuable: false,
        popupCoffinOpen: false,
        numberOfValuables: 0,
        loading: true
    };

    static getDerivedStateFromProps(props, state) {
        const firstLoad = !props.form.loading && state.loading;
        if (!firstLoad) return null;

        const { form } = props;
        const PlaceOfViewingItems = form.getField('PlaceOfViewingItems') || [];
        const RelectionRoomBookings = []; //form.getField('PlaceOfViewingItems') || [];
        const Clergy = form.getField('Clergy') || [];

        return {
            loading: props.form.loading,
            placeOfViewingRequired: PlaceOfViewingItems.length > 0,
            reflectionRoomRequired: RelectionRoomBookings.length > 0,
            clergyRequired: Clergy.length > 0
        };
    }

    componentDidUpdate(prevProps) {
        const { form } = prevProps;

        if (!form) return;

        const products = (form.getField('Disposal.DisposalBookingItems') || []).map(({ ProductID }) => ({ ProductID }));

        PreloadProducts(this, products);
    }

    render() {
        const { form } = this.props;
        const changes = form.getField('DetailsChangesFromPrePlan') || {};
        const changesCount = Object.keys(changes).length;

        return (
            <Fragment>
                {changesCount > 0 && (
                    <BiggerMessageBar>
                        Client made some changes through Pre-Plan form.&nbsp;
                        {
                            <PrimaryButton onClick={this.applyPrePlanChanges}>
                                <TickCircleIcon />
                                <Hidden smDown> Approve changes</Hidden>
                            </PrimaryButton>
                        }
                    </BiggerMessageBar>
                )}
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
                <ProductSelectionModal
                    onClose={() => this.setState({ popupCoffinOpen: false })}
                    open={!!this.state.popupCoffinOpen}
                    onConfirm={(coffinBookingItems, coffinProducts) =>
                        this.onSubmitCoffinBookingItems(coffinBookingItems, coffinProducts)
                    }
                    form={form}
                    formCartProperty="Disposal.DisposalBookingItems"
                    tabs={[
                        {
                            name: 'Coffin',
                            metadata: ProductMetadata.Coffins,
                            // stepLabel: 'Step One:',
                            stepTitle: 'Select one coffin or casket',
                            // stepSubtitle: 'Please select one coffin or casket from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Lining',
                            metadata: ProductMetadata.Lining,
                            // stepLabel: 'Step Two:',
                            stepTitle: 'Select a seal or lining for the coffin',
                            // stepSubtitle: 'Please select a seal or lining from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Handles',
                            metadata: ProductMetadata.Handles,
                            // stepLabel: 'Step Three:',
                            stepTitle: 'Select one set of coffin handles',
                            // stepSubtitle: 'Please select from the list of compatible handles below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Fittings',
                            metadata: ProductMetadata.Fittings,
                            // stepLabel: 'Step Four:',
                            stepTitle: 'Select  any coffin fittings',
                            // stepSubtitle: 'Please select the fittings from the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Name Plate',
                            metadata: ProductMetadata.NamePlates,
                            // stepLabel: 'Step Five:',
                            stepTitle: 'Select one Name Plate style',
                            // stepSubtitle: 'Please select a Name Plate form the list below:',
                            type: TabTypes.Selection
                        },
                        {
                            name: 'Summary',
                            // stepLabel: 'Step Six:',
                            stepTitle: 'Confirm your order',
                            // stepSubtitle: 'Please review your selections and confirm the order.',
                            type: TabTypes.Summary
                        }
                    ]}
                />
            </Fragment>
        );
    }

    applyPrePlanChanges = () => {
        const { form } = this.props;
        const changes = form.getField('DetailsChangesFromPrePlan');
        form.setField({ ...changes, DetailsChangesFromPrePlan: {} });
    };

    renderFirstColumn() {
        return (
            <Grid container spacing={16}>
                {this.renderPlaceOfService()}
                {1 === 2 && this.renderClergy()}
                {this.renderDisposalDetails()}
                {1 === 2 && this.renderAshes()}
                {this.renderGraveDetails()}
            </Grid>
        );
    }

    renderSecondColumn() {
        return (
            <Grid container spacing={16}>
                {/*{this.renderPlaceOfViewing()}*/}
                {this.renderCoffinDetails()}
            </Grid>
        );
    }

    renderPlaceOfService() {
        const { form } = this.props;
        const location = form.getField('PlaceOfService.Location');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Place of Service">
                    <Checkbox label="Private" form={form} name="PlaceOfService.Private" />
                    <Checkbox label="Allow Webcast" form={form} name="WebcastRequired" />
                </InlineHeader>

                <InlineField>
                    <AddressBookAutocomplete
                        placeholder="Search for a place of service"
                        categories={[ADDRESS_BOOK_CATEGORY.placeOfService]}
                        name="PlaceOfService.Location"
                        form={form}
                        labelFieldFunc={getLabelWithSuburb}
                    />
                </InlineField>
                {location && (
                    <InlineField>
                        <Grid item xs={12}>
                            <DisplayContact contactID={location.ID} />
                        </Grid>
                    </InlineField>
                )}
                <Grid item xs={12}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Select
                                allowNone={false}
                                label="Service type"
                                options={PLACE_OF_SERVICE_TYPE_OPTIONS}
                                name="PlaceOfService.Type"
                                form={form}
                            />
                        </Grid>
                        {/*<Grid item xs={12} sm={6}>
                            <TextField type="date" label="Date of Service" name="PlaceOfService.Date" form={form} />
                        </Grid>*/}
                    </Grid>

                    {/* <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField type="time" label="Time of Service" name="PlaceOfService.Time" form={form} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TimeSelect label="Duration (Hours)" form={form} name="PlaceOfService.Duration" />
                        </Grid>
                    </Grid>*/}
                </Grid>

                {/* <InlineField>
                    <Checkbox label="Webcast" form={form} name="WebcastRequired" />
                    {webcastRequired && (
                        <Checkbox label="Emailed link to family" form={form} name="WebcastEmailedLinkToFamily" />
                    )}
                </InlineField>

                {webcastRequired && (
                    <InlineField>
                        <TextField placeholder="Webcast link" form={form} name="WebcastComment" />
                        <Checkbox label="Confirmed" form={form} name="WebcastConfirmed" />
                    </InlineField>
                )}*/}
            </Grid>
        );
    }

    renderClergy() {
        const { form } = this.props;
        const { clergyRequired } = this.state;
        const clergy = getArray(this, 'Clergy');
        const contact = clergy.length > 0 ? clergy[0].Contact : null;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Clergy">
                    <Checkbox
                        label="Clergy required"
                        name="ClergyRequired"
                        checked={clergyRequired}
                        onChange={e => this.setState({ clergyRequired: e.target.checked })}
                    />
                </InlineHeader>
                {clergyRequired && (
                    <Fragment>
                        <InlineField>
                            <AddressBookAutocomplete
                                form={form}
                                name="Clergy[0].Contact"
                                placeholder="Search for a Clergy"
                                categories={[ADDRESS_BOOK_CATEGORY.clergy]}
                            />
                        </InlineField>

                        {isContactDefined(contact) && (
                            <InlineField>
                                <Grid item xs={12}>
                                    <ContactCard contact={contact} />
                                </Grid>
                            </InlineField>
                        )}

                        <InlineField>
                            <OptionalTextField
                                name="Clergy[0].Comment"
                                buttonlabel="+ Add Clergy comment"
                                textfieldlabel={'Clergy Comment'}
                                form={form}
                            />
                        </InlineField>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderDisposalDetails() {
        const { form } = this.props;
        const crematorium = form.getField('Disposal.Location');
        const placeOfCommittal = !!crematorium ? crematorium.ID : null;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Committal">
                    <Checkbox label="Private" form={form} name="Disposal.Private" />
                    <Checkbox label="No Attendance" form={form} name="Disposal.NoAttendance" />
                    {/*<Checkbox label="Follow to Committal" form={form} name="FollowToDisposal" />*/}
                </InlineHeader>

                <InlineField>
                    <RadioGroup options={DISPOSAL_METHOD_OPTIONS} form={form} name="Disposal.CrematedOrBuried" />
                </InlineField>

                {
                    <Fragment>
                        <InlineField>
                            <Label>Location of Committal</Label>
                        </InlineField>

                        <InlineField>
                            <AddressBookAutocomplete
                                placeholder="Search for location of Committal"
                                categories={[
                                    ADDRESS_BOOK_CATEGORY.placeOfCommittal,
                                    ADDRESS_BOOK_CATEGORY.placeOfService
                                ]}
                                name={'Disposal.Location'}
                                form={form}
                                labelFieldFunc={getLabelWithSuburb}
                            />
                        </InlineField>

                        {placeOfCommittal && (
                            <InlineField>
                                <Grid item xs={12}>
                                    <DisplayContact contactID={placeOfCommittal} />
                                </Grid>
                            </InlineField>
                        )}

                        <InlineField>
                            <Grid container spacing={16}>
                                <Grid item xs={12}>
                                    <OptionalTextField
                                        form={form}
                                        name={'Disposal.CemeterySection'}
                                        buttonlabel="+ Add Committal Comment"
                                        textfieldlabel={'Committal Comment'}
                                    />
                                </Grid>
                                {/*<Grid item xs={6} sm={4}>
                                    <TextField type="date" label="Date of committal" form={form} name="Disposal.Date" />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField type="time" label="Time of Service" form={form} name="Disposal.Time" />
                                </Grid>*/}
                            </Grid>
                        </InlineField>
                    </Fragment>
                }
            </Grid>
        );
    }

    renderTransportation() {
        const { arrangeForCar, arrangeForLimo } = this.state;
        const { classes, form } = this.props;
        const cars = getArray(this, 'Cars');
        const limos = getArray(this, 'Limos');

        return (
            <Fragment>
                <Grid bucket={true}>
                    <InlineHeader header="Cars">
                        <Checkbox
                            label="Arrange for a car"
                            onClick={this.onArrangeForCarChecked}
                            checked={arrangeForCar}
                        />
                    </InlineHeader>

                    {arrangeForCar && (
                        <Fragment>
                            {cars.map((_, i) => (
                                <Fragment key={i}>
                                    <InlineField>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="date"
                                                    label="Date of service"
                                                    form={form}
                                                    name={`Cars[${i}].Date`}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="time"
                                                    label="Time of service"
                                                    form={form}
                                                    name={`Cars[${i}].Time`}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TimeSelect label="Duration" form={form} name={`Cars[${i}].Duration`} />
                                            </Grid>
                                            <Grid item xs={6} sm={1} className={classes.alignWithLabel}>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    title={'Delete'}
                                                    onClick={() => this.deleteCar(i)}
                                                >
                                                    <DeleteIcon className={classes.icon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </InlineField>

                                    <InlineField>
                                        <Grid item xs={12} sm={11}>
                                            <TextField
                                                placeholder="Address/comments"
                                                form={form}
                                                name={`Cars[${i}].Address`}
                                            />
                                        </Grid>
                                    </InlineField>
                                </Fragment>
                            ))}

                            <InlineField>
                                <AltButton onClick={() => this.addCar()}>+ Add another car</AltButton>
                            </InlineField>
                        </Fragment>
                    )}
                </Grid>

                <Grid bucket={true}>
                    <InlineField>
                        <Label>Limo</Label>
                        <Checkbox
                            label="Arrange for limo"
                            onClick={this.onArrangeForLimoChecked}
                            checked={arrangeForLimo}
                        />
                    </InlineField>

                    {arrangeForLimo && (
                        <Fragment>
                            {limos.map((_, i) => (
                                <Fragment key={i}>
                                    <InlineField>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="date"
                                                    label="Date of service"
                                                    form={form}
                                                    name={`Limos[${i}].Date`}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <TextField
                                                    type="time"
                                                    label="Time of service"
                                                    form={form}
                                                    name={`Limos[${i}].Time`}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TimeSelect
                                                    label="Duration"
                                                    form={form}
                                                    name={`Limos[${i}].Duration`}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={1} className={classes.alignWithLabel}>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    title={'Delete'}
                                                    onClick={() => this.deleteLimo(i)}
                                                >
                                                    <DeleteIcon className={classes.icon} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </InlineField>

                                    <InlineField>
                                        <Grid item xs={12} sm={11}>
                                            <TextField
                                                placeholder="Address/comments"
                                                form={form}
                                                name={`Limos[${i}].Address`}
                                            />
                                        </Grid>
                                    </InlineField>
                                </Fragment>
                            ))}
                            <InlineField>
                                <AltButton onClick={() => this.addLimo()}>+ Add another Limo</AltButton>
                            </InlineField>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderPlaceOfViewing() {
        const { form, classes } = this.props;
        const PlaceOfViewingItems = getArray(this, 'PlaceOfViewingItems');
        const { placeOfViewingRequired } = this.state;
        const location = PlaceOfViewingItems.length > 0 ? PlaceOfViewingItems[0].Location : null;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Place of Viewing/Appointments">
                    <Checkbox onClick={this.onPlaceOfViewingChecked} checked={placeOfViewingRequired}>
                        Unconfirmed
                    </Checkbox>
                </InlineHeader>

                {placeOfViewingRequired && (
                    <Fragment>
                        {PlaceOfViewingItems.map((_, i) => (
                            <Fragment key={i}>
                                <InlineField>
                                    <AddressBookAutocomplete
                                        placeholder="Search for a place of viewing/appointment"
                                        categories={[ADDRESS_BOOK_CATEGORY.placeOfService]}
                                        name={`PlaceOfViewingItems[${i}].Location`}
                                        onSelect={(e, result) => this.onSelectPlaceOfViewing(result)}
                                        labelField={'Name'}
                                        form={form}
                                        labelFieldFunc={getLabelWithSuburb}
                                    />
                                </InlineField>

                                <Grid item xs={12}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                allowNone={false}
                                                label="Appointment type"
                                                options={PLACE_OF_VIEWING_TYPE_OPTIONS}
                                                name={`PlaceOfViewingItems[${i}].Type`}
                                                form={form}
                                            />
                                        </Grid>
                                        <Grid item xs={11} sm={5}>
                                            <TextField
                                                type="date"
                                                label="Date of Appointment"
                                                name={`PlaceOfViewingItems[${i}].Date`}
                                                form={form}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} className={classes.alignWithLabel}>
                                            <IconButton
                                                className={classes.iconButton}
                                                title={'Delete Place of Viewing'}
                                                onClick={() => this.deletePlaceOfViewing(i)}
                                            >
                                                <DeleteIcon className={classes.icon} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="time"
                                                label="Time of Appointment"
                                                name={`PlaceOfViewingItems[${i}].Time`}
                                                form={form}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TimeSelect
                                                label="Duration (Hours)"
                                                name={`PlaceOfViewingItems[${i}].Duration`}
                                                form={form}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <InlineField>
                                    <OptionalTextField
                                        name={`PlaceOfViewingItems[${i}].Comment`}
                                        form={form}
                                        buttonlabel="+ Add Appointment comment"
                                        textfieldlabel={'Appointment Comment'}
                                    />
                                </InlineField>

                                <InlineField>
                                    <Checkbox
                                        label="Private"
                                        name={`PlaceOfViewingItems[${i}].ViewingPrivate`}
                                        form={form}
                                    />
                                    <Checkbox
                                        label="Advertise"
                                        name={`PlaceOfViewingItems[${i}].TypeOfViewingAdv`}
                                        form={form}
                                    />
                                </InlineField>

                                <StaffAllocationTable
                                    staffAllocations={form.getField(`PlaceOfViewingItems[${i}].StaffAllocations`) || []}
                                    onChange={(i, obj) =>
                                        this.onChangeStaffAllocation(
                                            `PlaceOfViewingItems[${i}].StaffAllocations`,
                                            i,
                                            obj,
                                            location
                                        )
                                    }
                                    onDelete={i =>
                                        this.onDeleteStaffAllocation(`PlaceOfViewingItems[${i}].StaffAllocations`, i)
                                    }
                                    onAdd={obj =>
                                        this.onAddStaffAllocation(
                                            `PlaceOfViewingItems[${i}].StaffAllocations`,
                                            obj,
                                            location
                                        )
                                    }
                                    startTime={form.getField(`PlaceOfViewingItems[${i}].Time`)}
                                    duration={form.getField(`PlaceOfViewingItems[${i}].Duration`)}
                                    showCalendarLink={true}
                                    date={form.getField(`PlaceOfViewingItems[${i}].Date`)}
                                />
                            </Fragment>
                        ))}

                        <InlineField>
                            <PrimaryButton onClick={this.addPlaceOfViewing}>+ Add Appointment</PrimaryButton>
                        </InlineField>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderAshes() {
        const { form } = this.props;
        const showAshes = (form ? form.getField('Disposal.CrematedOrBuried') : null) === 'Cremated';
        if (!showAshes) return <Fragment />;
        const showCrematorium = form.getField('AshesDetail.FamilyHasReservation');
        const crematorium = form.getField('AshesDetail.Crematorium');

        return (
            <Grid bucket={true}>
                <InlineHeader header="Ashes" />
                <InlineField>
                    <Checkbox label="Family has reservation" form={form} name="AshesDetail.FamilyHasReservation" />
                </InlineField>
                {showCrematorium && (
                    <InlineField>
                        <AddressBookAutocomplete
                            placeholder="Search for a place of Crematorium"
                            categories={[ADDRESS_BOOK_CATEGORY.placeOfCommittal]}
                            name="AshesDetail.Crematorium"
                            form={form}
                            labelFieldFunc={getLabelWithSuburb}
                        />
                    </InlineField>
                )}
                {showCrematorium && isContactDefined(crematorium) && (
                    <InlineField>
                        <Grid item xs={12}>
                            <ContactCard contact={crematorium} />
                        </Grid>
                    </InlineField>
                )}
                <OptionalTextField
                    name="AshesDetail.Comment"
                    form={form}
                    buttonlabel="+ Add Ashes comment"
                    textfieldlabel={'Ashes Comment'}
                />
            </Grid>
        );
    }

    renderGraveDetails() {
        const { form, classes } = this.props;

        const crematedOrBuried = form ? form.getField('Disposal.CrematedOrBuried') : null;
        const showGraveDetails = crematedOrBuried === 'Buried' || crematedOrBuried === 'Repatriated';
        if (!showGraveDetails) return <Fragment />;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Grave Details" />

                <InlineField>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <Select
                                allowNone={false}
                                className={classes.alignWithFloatingLabel}
                                placeholder="Ground Type"
                                options={GROUND_TYPE_OPTIONS}
                                form={form}
                                name="Grave.GroundDescription"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select
                                allowNone={false}
                                className={classes.alignWithFloatingLabel}
                                placeholder="Depth of Grave"
                                options={GRAVE_DEPTH_OPTIONS}
                                form={form}
                                name="Grave.DepthOption"
                            />
                        </Grid>
                    </Grid>
                </InlineField>

                <InlineField>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Portion/garden name" form={form} name="Grave.PortionGardenName" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Beam/row/lot" form={form} name="Grave.BeamRowLot" />
                        </Grid>
                    </Grid>
                </InlineField>

                <InlineField>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Side/section" form={form} name="Grave.SideSection" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Grave number" name="Grave.Number" form={form} />
                        </Grid>
                    </Grid>
                </InlineField>

                <InlineField>
                    <Checkbox label="Reservation required" name="Grave.ReservationRequired" form={form} />
                </InlineField>
            </Grid>
        );
    }

    renderCoffinDetails() {
        const { form } = this.props;
        const CoffinProducts = form.getField('Disposal.DisposalBookingItems') || [];
        return (
            <Grid bucket={true}>
                <InlineHeader header="Coffin Details" />
                {CoffinProducts.length > 0 && (
                    <Grid item xs={12}>
                        <ImageList>
                            {CoffinProducts.sort((a, b) => {
                                if (a.URLSegment === 'coffins') return -1;
                                if (b.URLSegment === 'coffins') return 1;
                                return Number(a.ID) < Number(b.ID) ? -1 : 1;
                            }).map((value, i) => {
                                const product = ProductConfig.productMap[value.ProductID];
                                if (!product) return null;
                                if (value.Quantity && product.urlSegment === 'coffins') product.tileWidth = 2;
                                return (
                                    <ImageListItem
                                        key={i}
                                        value={product}
                                        image={product.imageLandscape}
                                        quantity={value.Quantity}
                                    />
                                );
                            })}
                        </ImageList>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PrimaryButton forModal onClick={() => this.setState({ popupCoffinOpen: true })}>
                        {!CoffinProducts || CoffinProducts.length === 0
                            ? 'Choose Coffin Details...'
                            : 'Edit / View Coffin Details...'}
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    }

    onSubmitCoffinBookingItems(coffinBookingItems, CoffinProducts) {
        const { form } = this.props;
        form.setField({ 'Disposal.DisposalBookingItems': coffinBookingItems });
    }

    onPlaceOfViewingChecked = e => {
        this.itemRequiredCheck(e, 'placeOfViewingRequired', 'PlaceOfViewingItems', this.addPlaceOfViewing);
    };

    addPlaceOfViewing = () => {
        this.addItem('PlaceOfViewingItems', {
            Location: '',
            ServiceType: PLACE_OF_SERVICE_TYPE_OPTIONS[0].value,
            Date: dateTimeToString(new Date()),
            Time: '14:00:00',
            Duration: 2,
            Private: false,
            Advertise: false
        });
    };

    deletePlaceOfViewing = i => {
        this.deleteItem('placeOfViewingRequired', 'PlaceOfViewingItems', i);
    };

    itemRequiredCheck = (e, stateProperty, arrayName, addFunc) => {
        this.setState({ [stateProperty]: e.target.checked });
        if (e.target.checked && getArray(this, arrayName).length <= 0) {
            addFunc();
        }
    };

    addItem = (arrayName, obj) => {
        const { form } = this.props;
        const array = getArray(this, arrayName);
        array.push(obj);
        form.setField({ [arrayName]: array });
    };

    deleteItem = (stateProperty, arrayName, index) => {
        const { form } = this.props;
        const array = getArray(this, arrayName);
        array.splice(index, 1);
        form.setField({ [arrayName]: array });

        if (array.length <= 0) this.setState({ [stateProperty]: false });
    };

    onSelectCrematorium = (e, result) => {
        const { form } = this.props;
        const CemeteryCremetorium = getArray(this, 'CemeteryCremetorium');
        if (CemeteryCremetorium.length === 0) {
            CemeteryCremetorium.push({ Contact: result });
        } else {
            CemeteryCremetorium[0].Contact = result;
        }
        form.setField({ CemeteryCremetorium });
    };

    onSelectPlaceOfViewing(result) {
        const { form } = this.props;
        const PlaceOfViewingItems = getArray(this, 'PlaceOfViewingItems');
        if (PlaceOfViewingItems.length === 0) {
            PlaceOfViewingItems.push({
                Location: result,
                NameAddress: result.Name
            });
        } else {
            PlaceOfViewingItems[0].Location = result;
            PlaceOfViewingItems[0].NameAddress = result.Name;
        }
        form.setField({ PlaceOfViewingItems });
    }
}

const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    iconButton: {
        height: '24px',
        width: '24px',
        marginTop: '12px'
    },

    icon: {
        height: '18px',
        width: '18px',
        verticalAlign: 'middle'
    }
});

export default withStyles(styles)(Details);
