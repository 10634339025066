import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import XMLParser from 'react-xml-parser';
import CloseIcon from '../../component/icon/CloseIcon';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../component/form/Table';
import { compose } from 'react-apollo/index';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { Tab, Tabs } from '@material-ui/core';
import { joinDefined } from '../../util/strings';

class OnlineOrderPopover extends Component {
    constructor(props) {
        super(props);
        // Set state function is passed down to children as a callback
        // so we need to bind the context of `this`
        this.setState = this.setState.bind(this);
    }

    state = {
        open: false,
        onlineOrderID: null,
        funeralID: null,
        tabIndex: 0
    };

    componentDidUpdate() {
        if (this.state.open !== this.props.open) {
            this.setState({
                open: this.props.open
            });
        }
    }

    render() {
        const { classes, orderData, pdfLink } = this.props;
        const { tabIndex, open } = this.state;
        const orderJSON = new XMLParser().parseFromString(orderData);
        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                aria-labelledby="OnlineOrder"
                aria-describedby="OnlineOrder"
                open={open}
                onClose={() => this.onClose()}
                className={classes.root}
            >
                <div className={classes.buttonClose}>
                    <Fab onClick={() => this.onClose()}>
                        <CloseIcon />
                    </Fab>
                </div>
                <div className={classes.paper}>
                    <Typography variant="headline" id="modal-title" className={classes.popoverTitle}>
                        Online Order Summary
                    </Typography>
                    {!!pdfLink && (
                        <a href={pdfLink} className={classes.summarylink} target="_blank" rel="noopener noreferrer">
                            Open PDF
                        </a>
                    )}

                    <div className={classes.paperScroll}>
                        <Tabs
                            onChange={this.handleTabChange}
                            aria-label="Online Order Tabs"
                            value={tabIndex}
                            indicatorColor="primary"
                        >
                            <Tab label="Order Details" />
                            <Tab label="Order Items" />
                        </Tabs>
                        <TabPanel index={0} value={tabIndex}>
                            <Table>
                                <TableHeaderRow>
                                    <TableHeaderCell>Name</TableHeaderCell>
                                    <TableHeaderCell>Value</TableHeaderCell>
                                </TableHeaderRow>
                                {orderJSON.getElementsByTagName('order').map((order, i) => (
                                    <Fragment key={i}>
                                        {this.renderTableRow(i, 'Online Order ID', order.attributes.ID, '')}
                                        {this.renderTableRow(i, 'Amount Paid', order.attributes.AmountPaid, '')}
                                        {this.renderTableRow(i, 'Amount Total', order.attributes.AmountTotal, '')}
                                        {this.renderTableRow(i, 'Applicant Email', order.attributes.ApplicantEmail, '')}
                                        {this.renderTableRow(
                                            i,
                                            'Applicant Name',
                                            joinDefined(
                                                [
                                                    (order.attributes.ApplicantSurname || '').toUpperCase(),
                                                    order.attributes.ApplicantFirstName
                                                ],
                                                ' '
                                            ),
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Applicant Phone', order.attributes.ApplicantPhone, '')}
                                        {this.renderTableRow(
                                            i,
                                            'Applicant Address',
                                            joinDefined(
                                                [
                                                    order.attributes.AddressLine1,
                                                    order.attributes.AddressLine2,
                                                    order.attributes.Suburb,
                                                    order.attributes.State,
                                                    order.attributes.Postcode
                                                ],
                                                ', '
                                            ),
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Billing Email', order.attributes.BillingEmail, '')}
                                        {this.renderTableRow(
                                            i,
                                            'Billing Name',
                                            joinDefined(
                                                [order.attributes.BillingFirstName, order.attributes.BillingSurname],
                                                ' '
                                            ),
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Billing Phone', order.attributes.BillingPhone, '')}
                                        {this.renderTableRow(i, 'Bearing', order.attributes.ID, '')}
                                        {this.renderTableRow(i, 'BearingByWho', order.attributes.BearingByWho, '')}
                                        {this.renderTableRow(i, 'BearingNeeded', order.attributes.BearingNeeded, '')}
                                        {this.renderTableRow(i, 'BurialAttend', order.attributes.BurialAttend, '')}
                                        {this.renderTableRow(i, 'BurialCustom', order.attributes.BurialCustom, '')}
                                        {this.renderTableRow(i, 'BurialOwn', order.attributes.BurialOwn, '')}
                                        {this.renderTableRow(i, 'BurialWhen', order.attributes.BurialWhen, '')}
                                        {this.renderTableRow(
                                            i,
                                            'CateringAdditionalComments',
                                            order.attributes.CateringAdditionalComments,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'CateringNeeded', order.attributes.CateringNeeded, '')}
                                        {this.renderTableRow(i, 'ClergyCustom', order.attributes.ClergyCustom, '')}
                                        {this.renderTableRow(i, 'ClergyNeeded', order.attributes.ClergyNeeded, '')}
                                        {this.renderTableRow(
                                            i,
                                            'ClergyOrganiser',
                                            order.attributes.ClergyOrganiser,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'ComplementaryNoticeNeeded',
                                            order.attributes.ComplementaryNoticeNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Completed', order.attributes.Completed, '')}
                                        {this.renderTableRow(i, 'Created', order.attributes.Created, '')}
                                        {this.renderTableRow(
                                            i,
                                            'CremationAttend',
                                            order.attributes.CremationAttend,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'CremationHasInstructions',
                                            order.attributes.CremationHasInstructions,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'CremationPriority',
                                            order.attributes.CremationPriority,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'CremationSpecialInstructions',
                                            order.attributes.CremationSpecialInstructions,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'DeceasedAlive', order.attributes.DeceasedAlive, '')}
                                        {this.renderTableRow(
                                            i,
                                            'DeceasedFirstName',
                                            order.attributes.DeceasedFirstName,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'DeceasedSurname',
                                            order.attributes.DeceasedSurname,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'DonationsNeeded',
                                            order.attributes.DonationsNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'FamilyTransportNeeded',
                                            order.attributes.FamilyTransportNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'FlowersNeeded', order.attributes.FlowersNeeded, '')}
                                        {this.renderTableRow(
                                            i,
                                            'FlowersOrganiser',
                                            order.attributes.FlowersOrganiser,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'ForWhom', order.attributes.ForWhom, '')}
                                        {this.renderTableRow(i, 'FuneralArea', order.attributes.FuneralArea, '')}
                                        {this.renderTableRow(i, 'FuneralBrand', order.attributes.FuneralBrand, '')}
                                        {this.renderTableRow(i, 'FuneralDate', order.attributes.FuneralDate, '')}
                                        {this.renderTableRow(i, 'FuneralTime', order.attributes.FuneralTime, '')}
                                        {this.renderTableRow(i, 'FuneralType', order.attributes.FuneralType, '')}
                                        {this.renderTableRow(
                                            i,
                                            'HowManyPeopleCaterFor',
                                            order.attributes.HowManyPeopleCaterFor,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Last4DigitsCC', order.attributes.Last4DigitsCC, '')}
                                        {this.renderTableRow(i, 'MemberEmail', order.attributes.MemberEmail, '')}
                                        {this.renderTableRow(
                                            i,
                                            'MemberFirstName',
                                            order.attributes.MemberFirstName,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'MemberSurname', order.attributes.MemberSurname, '')}
                                        {this.renderTableRow(i, 'MemberTitle', order.attributes.MemberTitle, '')}
                                        {this.renderTableRow(i, 'MusicNeeded', order.attributes.MusicNeeded, '')}
                                        {this.renderTableRow(i, 'MusicOrganiser', order.attributes.MusicOrganiser, '')}
                                        {this.renderTableRow(
                                            i,
                                            'NewspaperNoticeByYourSelf',
                                            order.attributes.NewspaperNoticeByYourSelf,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'NewspaperNoticeContent',
                                            order.attributes.NewspaperNoticeContent,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'NewspaperNoticeNeeded',
                                            order.attributes.NewspaperNoticeNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'OrganisationMember',
                                            order.attributes.OrganisationMember,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'OrganisationName',
                                            order.attributes.OrganisationName,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'PackageName', order.attributes.PackageName, '')}
                                        {this.renderTableRow(i, 'PaymentType', order.attributes.PaymentType, '')}
                                        {this.renderTableRow(
                                            i,
                                            'PhotoDisplayNeeded',
                                            order.attributes.PhotoDisplayNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'Religion', order.attributes.Religion, '')}
                                        {this.renderTableRow(
                                            i,
                                            'ServiceStreaming',
                                            order.attributes.ServiceStreaming,
                                            ''
                                        )}
                                        {this.renderTableRow(i, 'ServiceType', order.attributes.ServiceType, '')}
                                        {this.renderTableRow(i, 'Status', order.attributes.Status, '')}
                                        {this.renderTableRow(
                                            i,
                                            'UseApplicantInfo',
                                            order.attributes.UseApplicantInfo,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'ViewingVenueNeeded',
                                            order.attributes.ViewingVenueNeeded,
                                            ''
                                        )}
                                        {this.renderTableRow(
                                            i,
                                            'ViewingVenueSameVenue',
                                            order.attributes.ViewingVenueSameVenue,
                                            ''
                                        )}
                                    </Fragment>
                                ))}
                            </Table>
                        </TabPanel>

                        <TabPanel index={1} value={tabIndex}>
                            <Table>
                                <TableHeaderRow>
                                    <TableHeaderCell>Type</TableHeaderCell>
                                    <TableHeaderCell>Description</TableHeaderCell>
                                    <TableHeaderCell>Price&nbsp;($)</TableHeaderCell>
                                </TableHeaderRow>
                                {orderJSON.getElementsByTagName('order').map((order, i) => {
                                    return (
                                        <Fragment key={i}>
                                            {order.getElementsByTagName('orderItem').map((orderItem, j) => {
                                                let orderOption = orderItem
                                                    .getElementsByTagName('orderItemOption')
                                                    .map((orderItemOption, k) => {
                                                        return (
                                                            orderItemOption.attributes.Title +
                                                            ': ' +
                                                            orderItemOption.attributes.Value
                                                        );
                                                    })
                                                    .join(' - ');

                                                return this.renderTableRowWithOption(
                                                    j,
                                                    orderItem.attributes.ProductType,
                                                    orderItem.attributes.Title,
                                                    orderItem.attributes.Price,
                                                    orderOption,
                                                    orderItem.attributes.Comment
                                                );
                                            })}
                                            {order.getElementsByTagName('donationOrg').map((donationOrg, k) => {
                                                return this.renderTableRowWithOption(
                                                    'd' + k,
                                                    'Donation',
                                                    donationOrg.value,
                                                    '',
                                                    '',
                                                    donationOrg.attributes.Comment
                                                );
                                            })}
                                            {order.getElementsByTagName('song').map((song, j) => {
                                                return this.renderTableRowWithOption(
                                                    's' + j,
                                                    'Song',
                                                    (song.attributes.Artist || '') +
                                                        ' ' +
                                                        (song.attributes.Title || ''),
                                                    '',
                                                    song.attributes.Comment
                                                );
                                            })}
                                            {order.getElementsByTagName('orderInclusion').map((orderInclusion, j) => {
                                                return this.renderTableRowWithOption(
                                                    'i' + j,
                                                    'Inclusion',
                                                    orderInclusion.attributes.Title,
                                                    orderInclusion.attributes.Price,
                                                    '',
                                                    orderInclusion.attributes.Comment
                                                );
                                            })}
                                        </Fragment>
                                    );
                                })}
                            </Table>
                        </TabPanel>
                    </div>
                </div>
            </Dialog>
        );
    }

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.clear();
    }

    clear() {
        this.setState({ open: false });
    }

    onError(msg, obj) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        console.error(errorMessage);
        this.props.setSnackbarMessage(msg);
    }

    renderTableRowWithOption(i, name, value, price, options, comment) {
        return (
            <Fragment key={i}>
                <TableRow pad={true}>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                        {value}
                        {!!options && <div>Options : {options}</div>}
                        {!!comment && <div>Comment : {comment}</div>}
                    </TableCell>
                    <TableCell>{price}</TableCell>
                </TableRow>
            </Fragment>
        );
    }

    renderTableRow(i, name, value, options) {
        return (
            <TableRow pad={true}>
                <TableCell>{name}</TableCell>
                <TableCell>
                    {value} {options}
                </TableCell>
            </TableRow>
        );
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabIndex: newValue
        });
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ paddingTop: 6 }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

const styles = ({ spacing, typography, palette, transitions }) => ({
    root: {
        //need this hack so that the 'tick' button can appear in the top right corner
        '& > :nth-child(2)': {
            overflowY: 'visible',
            overflowX: 'visible',
            borderRadius: '10px',
            maxWidth: 'unset',
            '& > div': {
                overflowY: 'unset',
                flexDirection: 'unset'
            }
        }
    },
    buttonGroup: {
        marginTop: 12,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
    },
    popoverTitle: {
        margin: '0 6px'
    },
    paper: {
        maxWidth: 'calc(100vw - 64px)',
        width: 660,
        maxHeight: 'calc(100vh - 32px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: 25
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        marginTop: 24,
        padding: '12px 0'
    },
    actionButtons: {
        textAlign: 'right'
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    buttonClose: {
        position: 'absolute',
        top: '-1.5rem',
        right: '-1.5rem',
        '& > button > span > svg': {
            width: '30px',
            height: '30px'
        },
        '& > button': {
            background: palette.contentForeground.none,
            color: '#FFFFFF'
        }
    },
    iframe: {
        position: 'absolute',
        background: 'transparent',
        border: 0,
        marginTop: -8,
        pointerEvents: 'none'
    },
    summarylink: {
        textAlign: 'right'
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(OnlineOrderPopover);
