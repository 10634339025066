import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';
import Select from '../../../component/form/Select';
import TextField from '../../../component/form/TextField';
import RadioGroup from '../../../component/form/RadioGroup';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
    ABORIGINAL_OPTIONS,
    AGE_OPTIONS,
    DATE_OF_DEATH_OPTION,
    DATE_OF_DEATH_OPTIONS,
    GENDER_OPTIONS,
    PENSION_TYPE_OPTIONS,
    PENSIONER_OPTIONS,
    PLACE_OF_BIRTH_AUSTRALIA,
    PLACE_OF_BIRTH_OPTIONS,
    PLACE_OF_BIRTH_OVERSEAS,
    PLACE_OF_BIRTH_UNKNOWN,
    PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS,
    PLACE_OF_DEATH_TYPE,
    RESIDENTIAL_ADDRESS_COMPONENTS,
    RETIRED_OPTIONS,
    TORRES_OPTIONS,
    TRANSFER_FROM_RESIDENTIAL_COMPONENTS
} from './DeceasedConstants';

import ColumnLayout from '../../../component/ColumnLayout';
import PhotoModal from '../../../component/form/PhotoModal';
import AddressBookAutocomplete from '../../../component/form/AddressBookAutocomplete';
import { ADDRESS_BOOK_CATEGORY } from '../funeralConstants';
import { joinDefined, stringIsNullOrEmpty } from '../../../util/strings';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import FileUpload, { getFileName } from '../../../component/form/FileUpload';
import { ImageList, ImageListItem } from '../../../component/form/ImageList';
import { Hidden, Typography } from '@material-ui/core';
import { niceDateFromString, niceDateTimeFromString, nicePeriod } from '../../../util/date';
import PrimaryButton, { AltButton } from '../../../component/form/PrimaryButton';
import { getClient } from '../../../apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import MortuaryIcon from '../../../component/icon/MortuaryIcon';
import { CONTACT_AUS_STATES } from '../../contacts/contactConstants';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import UserCircleIcon from '../../../component/icon/UserCircleIcon';
import LinkButton from '../../../component/form/LinkButton';
import { getSiteConfig } from '../../../environment';

class Deceased extends Component {
    state = {
        funeralId: undefined,
        surnameDifferent: null,
        birthplaceUnknown: null,
        bornOverseas: null,

        addressBookModalField: undefined,
        addressBookModalCategory: undefined,
        selectedPhoto: null,
        placeOfDeathBusinessLocation: null
    };

    componentDidMount() {
        this.updateStateFromProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateStateFromProps(nextProps);
    }

    returnedBusinessLocation = item => {
        this.setState({ placeOfDeathBusinessLocation: item });
    };

    updateStateFromProps(props) {
        const { form } = props;
        const { funeralId } = this.state;
        if (!form || !!form.loading) return;
        if (!!form.fields) {
            const {
                ID,
                FamilyNameAtBirth,
                Surname,
                PlaceOfBirth1,
                PlaceOfBirthCountry,
                DateOfBirth,
                DateTransferredToMortuary
            } = form.fields;

            if (ID === funeralId) return;

            let placeOfBirthOption;

            if (PlaceOfBirth1 === 'Unknown' && !PlaceOfBirthCountry) {
                placeOfBirthOption = PLACE_OF_BIRTH_UNKNOWN;
            } else if (PlaceOfBirthCountry !== 'Australia') {
                placeOfBirthOption = PLACE_OF_BIRTH_OVERSEAS;
            } else {
                placeOfBirthOption = PLACE_OF_BIRTH_AUSTRALIA;
            }

            if (!DateTransferredToMortuary) {
                readMortuaryRequired(ID).then(mortuaryWaiting => this.setState({ mortuaryWaiting }));
            }

            this.setState({
                funeralId: ID,
                placeOfBirthOption,
                surnameDifferent: !stringIsNullOrEmpty(FamilyNameAtBirth) && FamilyNameAtBirth !== Surname,
                FamilyNameAtBirth,
                PlaceOfBirth1,
                PlaceOfBirthCountry,
                DateOfBirth,
                DateTransferredToMortuary,
                mortuaryWaiting: !!DateTransferredToMortuary ? null : ['(checking, please wait...)']
            });
        }
    }

    onToggleField = (event, fieldList, reversed) => {
        const { form } = this.props;
        const { name, checked } = event.target;

        this.setState({ [name]: !!checked });
        let fields = fieldList;
        if (!Array.isArray(fields)) fields = fields.split(',');

        fields.forEach(field => {
            if (!reversed) {
                form.setField({ [field]: !!checked ? null : this.state[field] });
            } else {
                form.setField({ [field]: !checked ? null : this.state[field] });
            }
        });
    };

    render() {
        const { selectedPhoto } = this.state;
        const { form, locked, classes } = this.props;
        const changes = form.getField('DeceasedChangesFromBDM') || {};
        const changesCount = Object.keys(changes).length;

        const ID = form.getField('ID') || 0;
        const LegacyKey = form.getField('LegacyKey') || '';
        const LatestBDMFormID = form.getField('LatestBDMForm.ID') || 0;
        const regoForm =
            getSiteConfig().getDeathRegistrationPage +
            '?Type=funeral&ID=' +
            ID +
            '&Key=' +
            LegacyKey +
            '&FormID=' +
            (LatestBDMFormID > 0 ? LatestBDMFormID : 'latest');

        return (
            <Fragment>
                <PhotoModal photo={selectedPhoto} onClose={() => this.setState({ selectedPhoto: null })} />
                {changesCount > 0 && !locked && (
                    <div className={classes.noColumn}>
                        <BiggerMessageBar messageType="approve">
                            Customer sent changes using the{' '}
                            <LinkButton href={regoForm} target="_blank" text="Death Registration Form" /> and they need
                            review below.&nbsp;
                            {
                                <PrimaryButton onClick={this.applyBDMChanges}>
                                    <TickCircleIcon />
                                    <Hidden smDown> Approve All Changes</Hidden>
                                </PrimaryButton>
                            }
                        </BiggerMessageBar>
                    </div>
                )}
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    applyBDMChanges = () => {
        const { form } = this.props;
        const changes = form.getField('DeceasedChangesFromBDM');
        form.setField({ ...changes, DeceasedChangesFromBDM: {} });
        this.setState({ surnameDifferent: form.getField('FamilyNameAtBirth') !== '' });
    };

    renderDeathDateAndAge() {
        const { form, locked } = this.props;
        const birthday = form.getField('DateOfBirth');
        const deathday = form.getField('DateOfDeath1');
        const unknownDeathDate = form.getField('DateOfDeathType') === DATE_OF_DEATH_OPTION.UNKNOWN.value;
        const unknownBirthDate = form.getField('UnknownDOB');
        const manualAge = form.getField('ManualAge');
        const ageCode = form.getField('AgeCode');
        const ageCodeNeedsAge = ['Stillborn', 'Not Stated', 'Unknown'].indexOf(ageCode) < 0;
        return (
            <Fragment>
                <Grid pc={1} style={{ paddingBottom: 0 }}>
                    <Label disabled={locked} text="Date of Death" htmlFor="text-DateOfDeath1" />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                    <Select
                        disabled={locked}
                        name="DateOfDeathType"
                        options={DATE_OF_DEATH_OPTIONS}
                        form={form}
                        allowNone={false}
                        required
                    />
                </Grid>
                {form.getField('DateOfDeathType') !== DATE_OF_DEATH_OPTION.UNKNOWN.value &&
                    (form.getField('DateOfDeathType') === DATE_OF_DEATH_OPTION.BETWEEN.value ? (
                        <Fragment>
                            <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                <TextField
                                    disabled={locked}
                                    name="DateOfDeath1"
                                    type="date"
                                    notFuture
                                    form={form}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                <TextField
                                    disabled={locked}
                                    name="DateOfDeath2"
                                    type="date"
                                    notFuture
                                    form={form}
                                    required
                                />
                            </Grid>
                        </Fragment>
                    ) : (
                        <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                            <TextField
                                disabled={locked}
                                name="DateOfDeath1"
                                type="date"
                                notFuture
                                form={form}
                                required
                            />
                        </Grid>
                    ))}
                {!(unknownDeathDate || unknownBirthDate) && (
                    <Grid pc={1}>
                        <Typography>
                            Age:{' '}
                            <strong>
                                {(ageCodeNeedsAge &&
                                    ((birthday &&
                                        moment(deathday).diff(birthday, 'day') > 1 &&
                                        nicePeriod(birthday, deathday)) ||
                                        ` ${form.getField('Age') || 0} ${ageCode || ''}`.toLowerCase())) ||
                                    ageCode}
                            </strong>
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <InlineField>
                        {(!(unknownDeathDate || unknownBirthDate) && (
                            <Checkbox
                                disabled={locked}
                                form={form}
                                name={'ManualAge'}
                                label={manualAge ? 'Override' : 'Override age calculation'}
                            />
                        )) || <Label>Age</Label>}
                        {(manualAge || unknownDeathDate || unknownBirthDate) && (
                            <Fragment>
                                {ageCodeNeedsAge && (
                                    <TextField
                                        disabled={locked}
                                        fullWidth={false}
                                        form={form}
                                        name={'Age'}
                                        type={'number'}
                                        onChange={() => {
                                            form.setField({ ManualAge: true });
                                        }}
                                    />
                                )}
                                <Select
                                    disabled={locked}
                                    form={form}
                                    name={'AgeCode'}
                                    options={AGE_OPTIONS}
                                    onChange={() => {
                                        form.setField({ ManualAge: true });
                                    }}
                                />
                            </Fragment>
                        )}
                    </InlineField>
                </Grid>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const { form, locked } = this.props;
        const { surnameDifferent } = this.state;
        const transferFromResidential = form.getField('TransferFromType') === PLACE_OF_DEATH_TYPE.Residential;
        const placeOfDeathResidential = form.getField('PlaceOfDeathType') === PLACE_OF_DEATH_TYPE.Residential;
        const sameAsPlaceOfDeath = form.getField('UsePlaceOfDeathAddress');
        const sameAsResidential = form.getField('UseResidentialAddress');
        const { placeOfDeathBusinessLocation } = this.state;

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Name" />
                    </Grid>

                    <Grid pc={0.5}>
                        <TextField
                            disabled={locked}
                            name="FirstName"
                            label="First name"
                            form={form}
                            required
                            titleCase
                        />
                    </Grid>

                    <Grid pc={0.5}>
                        <TextField disabled={locked} name="MiddleName" label="Middle name(s)" form={form} titleCase />
                    </Grid>

                    <Grid pc={0.5}>
                        <TextField disabled={locked} name="Surname" label="Surname" form={form} required titleCase />
                    </Grid>

                    <Grid pc={0.5}>
                        <TextField disabled={locked} name="KnownAs" label="Known as" form={form} />
                    </Grid>

                    <Grid pc={1}>
                        <ValidationPlaceholder validationResult={form.getValidation('SurnameAtBirth', true)}>
                            <Label text="Surname at birth" htmlFor="text-FamilyNameAtBirth" />
                            <Grid container spacing={16}>
                                {!!surnameDifferent && (
                                    <Grid pc={0.5}>
                                        <TextField
                                            disabled={locked}
                                            name="FamilyNameAtBirth"
                                            placeholder="Surname at Birth"
                                            form={form}
                                            required={!!surnameDifferent}
                                            onBlur={e =>
                                                this.setState({
                                                    surnameDifferent: !!e.target.value,
                                                    FamilyNameAtBirth: e.target.value
                                                })
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid pc={0.5}>
                                    <Checkbox
                                        disabled={locked}
                                        label="Surname at Birth was different"
                                        checked={!!surnameDifferent}
                                        name={'surnameDifferent'}
                                        onChange={e => this.onToggleField(e, 'FamilyNameAtBirth', 1)}
                                    />
                                </Grid>
                            </Grid>
                        </ValidationPlaceholder>
                    </Grid>

                    <Grid pc={0.5}>
                        <Label text="Gender" htmlFor="select-Gender" />
                        <Select disabled={locked} name="Gender" options={GENDER_OPTIONS} form={form} required />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={12}>
                        <Label text="Residential Address" htmlFor="ResidentialAddress" />
                        <AddressAutocomplete
                            disabled={locked}
                            id="ResidentialAddress"
                            placeholder="Search for residential address"
                            componentFields={RESIDENTIAL_ADDRESS_COMPONENTS}
                            form={form}
                            allowCustomAddress={true}
                        />
                    </Grid>
                    <Grid pc={1}>
                        <Label text="Date of Birth" htmlFor="text-DateOfBirth" />
                        <Grid container item spacing={24}>
                            {!form.getField('UnknownDOB') && (
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        disabled={locked}
                                        name="DateOfBirth"
                                        type="date"
                                        notFuture
                                        form={form}
                                        onBlur={e =>
                                            this.setState({
                                                DateOfBirth: e.target.value
                                            })
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6} sm={6}>
                                <Checkbox
                                    disabled={locked}
                                    name="UnknownDOB"
                                    label="Date of Birth unknown"
                                    form={form}
                                    onChange={e => this.onToggleField(e, 'DateOfBirth')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    {this.renderDeathDateAndAge()}
                    <Grid pc={1} />

                    <Grid pc={1}>
                        <InlineHeader header="Place of Death">
                            <Checkbox
                                disabled={locked}
                                name="UseResidentialAddress"
                                label="Same as residential address"
                                form={form}
                            />
                            <Checkbox
                                name="UseResidentialAddress"
                                label="Use a NSW Hospital/Nursing Home"
                                checked={!placeOfDeathResidential && !sameAsResidential}
                                onChange={e => {
                                    form.setField({
                                        PlaceOfDeathType: placeOfDeathResidential
                                            ? PLACE_OF_DEATH_TYPE.AddressBook
                                            : PLACE_OF_DEATH_TYPE.Residential
                                    });
                                }}
                                disabled={sameAsResidential || locked}
                            />
                        </InlineHeader>

                        <ValidationPlaceholder
                            validationResult={
                                form.getValidation('PlaceOfDeath.AddressLine1', true) ||
                                form.getValidation('PlaceOfDeathResidentialAddress.AddressLine1', true)
                            }
                        >
                            {sameAsResidential ? (
                                <TextField
                                    placeholder="Residential Address"
                                    value={joinDefined(
                                        [
                                            form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.line1),
                                            form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.line2),
                                            form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.city),
                                            form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.state),
                                            form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.code)
                                        ],
                                        ', '
                                    )}
                                    disabled={true}
                                />
                            ) : placeOfDeathResidential ? (
                                <Fragment>
                                    {!stringIsNullOrEmpty(placeOfDeathBusinessLocation) && (
                                        <FormHelperText>{placeOfDeathBusinessLocation}</FormHelperText>
                                    )}

                                    <AddressAutocomplete
                                        id="PlaceOfDeath"
                                        placeholder="Search for address"
                                        componentFields={PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS}
                                        onReturnValue={this.returnedBusinessLocation}
                                        disabled={!!form.getField('UseResidentialAddress') || locked}
                                        form={form}
                                        allowCustomAddress={true}
                                    />
                                </Fragment>
                            ) : (
                                <AddressBookAutocomplete
                                    disabled={locked}
                                    noAddNew
                                    name="PlaceOfDeath"
                                    form={form}
                                    categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                                />
                            )}
                        </ValidationPlaceholder>
                    </Grid>
                    <Grid pc={1} />
                    <Grid pc={1}>
                        <InlineHeader header="Transfer From">
                            <Checkbox
                                disabled={locked}
                                name="UsePlaceOfDeathAddress"
                                label="Same as place of death address"
                                form={form}
                            />
                            <Checkbox
                                name="UsePlaceOfDeathAddress"
                                label="Use a NSW Hospital/Nursing Home"
                                checked={!transferFromResidential && !sameAsPlaceOfDeath}
                                onChange={e => {
                                    form.setField({
                                        TransferFromType: transferFromResidential
                                            ? PLACE_OF_DEATH_TYPE.AddressBook
                                            : PLACE_OF_DEATH_TYPE.Residential
                                    });
                                }}
                                disabled={sameAsPlaceOfDeath || locked}
                            />
                        </InlineHeader>

                        {sameAsPlaceOfDeath && sameAsResidential ? (
                            <TextField
                                placeholder="Residential Address"
                                value={joinDefined(
                                    [
                                        form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.line1),
                                        form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.line2),
                                        form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.city),
                                        form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.state),
                                        form.getField(RESIDENTIAL_ADDRESS_COMPONENTS.code)
                                    ],
                                    ', '
                                )}
                                disabled={true}
                            />
                        ) : sameAsPlaceOfDeath && !placeOfDeathResidential ? (
                            <TextField
                                placeholder="Place of Death Address"
                                value={form.getField('PlaceOfDeath') ? form.getField('PlaceOfDeath').Name : null}
                                disabled={true}
                            />
                        ) : sameAsPlaceOfDeath && placeOfDeathResidential ? (
                            <TextField
                                placeholder="Place of Death Residential Address"
                                value={joinDefined(
                                    [
                                        form.getField(PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.line1),
                                        form.getField(PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.line2),
                                        form.getField(PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.city),
                                        form.getField(PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.state),
                                        form.getField(PLACE_OF_DEATH_RESIDENTIAL_COMPONENTS.code)
                                    ],
                                    ', '
                                )}
                                disabled={true}
                            />
                        ) : transferFromResidential ? (
                            <AddressAutocomplete
                                id="TransferFrom"
                                placeholder="Search for address"
                                componentFields={TRANSFER_FROM_RESIDENTIAL_COMPONENTS}
                                disabled={!!form.getField('UsePlaceOfDeathAddress') || locked}
                                form={form}
                                allowCustomAddress={true}
                            />
                        ) : (
                            <AddressBookAutocomplete
                                disabled={locked}
                                noAddNew
                                name="TransferFrom"
                                form={form}
                                categories={[ADDRESS_BOOK_CATEGORY.hospitalNursingHome]}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderSecondColumn() {
        const { classes, form, locked } = this.props;

        return (
            <Grid container spacing={24}>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <Label text="Occupation" htmlFor="text-Occupation" />
                        <ComboListAutoComplete
                            disabled={locked}
                            placeholder="Occupation"
                            name="Occupation"
                            form={form}
                            category={'OCCUPATION'}
                        />
                    </Grid>

                    <Grid pc={1}>
                        <RadioGroup
                            disabled={locked}
                            name="Retired"
                            label="Retired"
                            options={RETIRED_OPTIONS}
                            form={form}
                            labelProps={{
                                className: classes.retPenRadioLabel
                            }}
                        />
                    </Grid>

                    <Grid pc={1} className={classes.pensionGroup}>
                        <RadioGroup
                            disabled={locked}
                            name="Pensioner"
                            label="Pensioner"
                            options={PENSIONER_OPTIONS}
                            form={form}
                            className={classes.pensionRadio}
                            labelProps={{
                                className: classes.retPenRadioLabel
                            }}
                            fullWidth={false}
                        />
                        {form.getField('Pensioner') === 'Y' && (
                            <Select
                                disabled={locked}
                                name="PensionType"
                                options={PENSION_TYPE_OPTIONS}
                                form={form}
                                fullWidth={false}
                                className={classes.pensionSelect}
                                label="Pension Type"
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <RadioGroup
                            disabled={locked}
                            name="Aboriginal"
                            label="Aboriginal"
                            options={ABORIGINAL_OPTIONS}
                            form={form}
                            value={!form.getField('Aboriginal') ? 'N' : form.getField('Aboriginal')}
                            labelProps={{
                                className: classes.aboTorRadioLabel
                            }}
                        />
                    </Grid>

                    <Grid pc={1}>
                        <RadioGroup
                            disabled={locked}
                            name="TorresStrait"
                            label="Torres Strait Islander"
                            options={TORRES_OPTIONS}
                            form={form}
                            value={!form.getField('TorresStrait') ? 'N' : form.getField('TorresStrait')}
                            labelProps={{
                                className: classes.aboTorRadioLabel
                            }}
                        />
                    </Grid>

                    {this.renderPlaceOfBirthOptions()}
                </Grid>

                {this.renderMortuary()}
            </Grid>
        );
    }

    renderPlaceOfBirthOptions() {
        const { form, classes, locked } = this.props;
        const { placeOfBirthOption } = this.state;
        const yearMax = new Date().getFullYear();
        const yearMin = yearMax - 120;
        const yearPlaceholder = (yearMin + (yearMax - yearMin) / 2.6).toFixed(0);

        return (
            <Grid pc={1}>
                <Grid item xs={12}>
                    <RadioGroup
                        disabled={locked}
                        labelProps={{
                            className: classes.aboTorRadioLabel
                        }}
                        label="Place of Birth"
                        options={PLACE_OF_BIRTH_OPTIONS}
                        value={placeOfBirthOption}
                        onChange={e => this.handleChangePlaceOfBirthOption(e)}
                    />
                </Grid>
                {placeOfBirthOption !== PLACE_OF_BIRTH_UNKNOWN && (
                    <Fragment>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                <TextField
                                    disabled={locked}
                                    name="PlaceOfBirth1"
                                    label="City of Birth"
                                    InputLabelProps={{ shrink: true }}
                                    form={form}
                                />
                            </Grid>
                            {(placeOfBirthOption === PLACE_OF_BIRTH_AUSTRALIA && (
                                <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                    <Select
                                        disabled={locked}
                                        allowNone
                                        options={CONTACT_AUS_STATES}
                                        label="State/Territory of Birth"
                                        InputLabelProps={{ shrink: true }}
                                        form={form}
                                        name="PlaceOfBirthState"
                                    />
                                </Grid>
                            )) || (
                                <Fragment>
                                    <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                        <TextField
                                            disabled={locked}
                                            label="State/Province of Birth"
                                            InputLabelProps={{ shrink: true }}
                                            form={form}
                                            name="PlaceOfBirthState"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={6} xl={4}>
                                        <TextField
                                            disabled={locked}
                                            label="Country of Birth"
                                            InputLabelProps={{ shrink: true }}
                                            form={form}
                                            name="PlaceOfBirthCountry"
                                            onBlur={e => this.handleChangePlaceOfBirthAddress(e.target.value)}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Fragment>
                )}
                {placeOfBirthOption === PLACE_OF_BIRTH_OVERSEAS && (
                    <Fragment>
                        <br />
                        <Label text="Year entered Australia" htmlFor="text-DateEnteredAustralia" />
                        <Grid container spacing={24}>
                            {!form.getField('DateEnteredAustraliaUnknown') && (
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        disabled={locked}
                                        name="DateEnteredAustralia"
                                        type="number"
                                        placeholder={`Enter a year, ie ${yearPlaceholder}`}
                                        min={yearMin}
                                        max={yearMax}
                                        step={1}
                                        notFuture
                                        form={form}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6} sm={8}>
                                <Checkbox
                                    disabled={locked}
                                    name="DateEnteredAustraliaUnknown"
                                    form={form}
                                    label="Year Unknown"
                                    onChange={e => form.setField({ DateEnteredAustralia: null })}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    //if they have selected non-australia. but enter an australian address, we flip the toggle.
    handleChangePlaceOfBirthAddress({ PlaceOfBirthCountry }) {
        let { placeOfBirthOption } = this.state;

        if (placeOfBirthOption !== PLACE_OF_BIRTH_AUSTRALIA && PlaceOfBirthCountry === 'Australia') {
            this.setState({ placeOfBirthOption: PLACE_OF_BIRTH_AUSTRALIA });
        } else if (placeOfBirthOption === PLACE_OF_BIRTH_AUSTRALIA && PlaceOfBirthCountry !== 'Australia') {
            this.setState({ placeOfBirthOption: PLACE_OF_BIRTH_OVERSEAS });
        }
    }

    handleChangePlaceOfBirthOption(e) {
        const { form } = this.props;
        const { PlaceOfBirthCountry } = this.state;

        let newFormState = {
            DateEnteredAustralia: null,
            PlaceOfBirth1: null,
            PlaceOfBirthState: null,
            PlaceOfBirthCountry: null
        };

        if (e.target.value === PLACE_OF_BIRTH_AUSTRALIA) {
            newFormState.PlaceOfBirthCountry = 'Australia';
        } else if (e.target.value === PLACE_OF_BIRTH_OVERSEAS) {
            newFormState.PlaceOfBirthCountry = PlaceOfBirthCountry !== 'Australia' ? PlaceOfBirthCountry : null;
        } else if (e.target.value === PLACE_OF_BIRTH_UNKNOWN) {
            newFormState.PlaceOfBirth1 = 'Unknown';
        }

        form.setField(newFormState);
        this.setState({ placeOfBirthOption: e.target.value });
    }

    renderMortuary() {
        const { form, classes, locked } = this.props;
        const { mortuaryWaiting } = this.state;
        const {
            ReadyForMortuary,
            DateTransferredToMortuary,
            DatetimeReadyForMortuaryCheckout,
            DatetimeOfMortuaryCheckout,
            MortuaryActionReturnedReason
        } = form.fields;
        const photos = form.getField('DeceasedPhotos') || [];
        const reference = form.getField('ReferencePhoto');
        return (
            <Grid bucket={true} container spacing={24}>
                <Grid item xs={12}>
                    <Label>Mortuary Status</Label>

                    {(!!DatetimeReadyForMortuaryCheckout && (
                        <Typography className={classes.peeGap}>
                            {(!DatetimeOfMortuaryCheckout && (
                                <Fragment>
                                    <strong>Ready for Mortuary Checkout:</strong>{' '}
                                    {niceDateTimeFromString(DatetimeReadyForMortuaryCheckout)}
                                </Fragment>
                            )) || (
                                <Fragment>
                                    <strong>Checkout Completed:</strong>{' '}
                                    {niceDateTimeFromString(DatetimeOfMortuaryCheckout)}
                                </Fragment>
                            )}
                        </Typography>
                    )) || (
                        <Typography className={classes.peeGap}>
                            {!!DateTransferredToMortuary ? (
                                <Fragment>
                                    <strong>Ready for Mortuary:</strong> {niceDateFromString(DateTransferredToMortuary)}
                                </Fragment>
                            ) : (
                                !!ReadyForMortuary && (
                                    <strong>
                                        Is Not Ready for Mortuary, but forced into Mortuary anyway.
                                        <br />
                                    </strong>
                                )
                            )}
                            {!!mortuaryWaiting && mortuaryWaiting.length > 0 && (
                                <Fragment>
                                    <strong>Required for Mortuary Ready:</strong> {joinDefined(mortuaryWaiting, ', ')}.
                                </Fragment>
                            )}
                        </Typography>
                    )}

                    {!DateTransferredToMortuary && !ReadyForMortuary && (
                        <Typography className={classes.peeGap}>
                            <AltButton
                                disabled={locked}
                                onClick={e => {
                                    form.setField({
                                        ReadyForMortuary: true
                                    });
                                }}
                            >
                                <MortuaryIcon /> Force into Mortuary
                            </AltButton>
                        </Typography>
                    )}

                    {!DatetimeOfMortuaryCheckout && !!MortuaryActionReturnedReason && (
                        <Typography className={classes.peeGap}>
                            <strong>Mortuary Issue:</strong> {MortuaryActionReturnedReason}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Label>Reference Photo</Label>
                </Grid>
                <ValidationPlaceholder validationResult={form.getValidation('ReferencePhoto', true)}>
                    <Grid item xs={12}>
                        {reference && reference.ID > 0 ? (
                            <ImageList>
                                <ImageListItem
                                    value={{ image: reference.AbsoluteLink, label: reference.Name, tileWidth: 3 }}
                                    onClick={() => this.setState({ selectedPhoto: reference })}
                                />
                            </ImageList>
                        ) : (
                            <BiggerMessageBar variant={'info'}>
                                Please select one of the photos of deceased to be the reference photo.
                            </BiggerMessageBar>
                        )}
                    </Grid>
                </ValidationPlaceholder>
                <Grid item xs={12}>
                    <Label>Deceased Photos</Label>
                    {photos && photos.length > 0 ? (
                        <ImageList>
                            {photos.map((photo, i) => (
                                <ImageListItem
                                    key={i}
                                    value={{ image: photo.AbsoluteLink, label: photo.Name }}
                                    onClick={() => this.setState({ selectedPhoto: photo })}
                                    onDelete={locked ? null : () => this.removeUpload(i)}
                                    customAction={
                                        locked
                                            ? null
                                            : {
                                                  title: 'Set as Reference Photo',
                                                  icon: <UserCircleIcon />,
                                                  handler: () => {
                                                      form.setField({ ReferencePhoto: photo });
                                                  }
                                              }
                                    }
                                />
                            ))}
                        </ImageList>
                    ) : (
                        <FormHelperText>
                            There are currently no photos of the deceased. Please upload a photo and select it as the
                            reference photo.
                        </FormHelperText>
                    )}
                </Grid>

                <Grid item xs={12}>
                    {!locked && this.renderNewPhoto()}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Label text="Weight of Deceased" />
                    <TextField
                        disabled={locked}
                        placeholder={'Enter the estimated weight...'}
                        type="number"
                        form={form}
                        name="EstimatedDeceasedWeight"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">KG</InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    renderNewPhoto() {
        const { classes, form } = this.props;
        return (
            <Fragment>
                <div className={classes.uploadButton}>
                    <FileUpload
                        label={'Upload Image'}
                        accept={'image/*'}
                        buttonOnly={true}
                        folderPath={`/documents/funeral/${form.getField('ID')}`}
                        onComplete={({ uploadFile }) => this.onFileUploaded(uploadFile)}
                    />
                </div>
            </Fragment>
        );
    }

    onFileUploaded({ id, url, filename }) {
        const { form } = this.props;
        const DeceasedPhotos = form.getField('DeceasedPhotos') || [];

        DeceasedPhotos.push({
            ID: id,
            AbsoluteLink: url,
            Name: getFileName(filename)
        });

        form.setField({ DeceasedPhotos });
    }

    removeUpload(index) {
        const { form } = this.props;
        const DeceasedPhotos = form.getField('DeceasedPhotos');

        DeceasedPhotos.splice(index, 1);
        form.setField({ DeceasedPhotos });
    }
}

const readOneFuneralQuery = gql`
    query loadMortuaryRequired($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            FirstName
            Surname
            Gender
            PlaceOfViewingRequired
            Disposal {
                ID
                Date
                CrematedOrBuried
                DisposalBookingItems {
                    ID
                    Product {
                        ID
                        ProductCategories {
                            ... on CoreProductCategory {
                                ID
                                URLSegment
                            }
                        }
                    }
                }
            }
            PlaceOfService {
                ID
                Date
                Type
            }
            PlaceOfViewingItems {
                ID
                Date
            }
            Arrangers {
                ID
            }
            Coordinators {
                ID
            }
            FuneralDressing {
                ID
                Status
            }
        }
    }
`;

const readMortuaryRequired = async funeralId => {
    const fields = [];
    if (!funeralId) return fields;
    const asyncQuery = await getClient().query({
        query: readOneFuneralQuery,
        variables: { id: funeralId }
    });
    if (!asyncQuery || !asyncQuery.data) return fields;

    const funeral = asyncQuery.data.readOneFuneral;
    if (!funeral.FirstName) fields.push('First Name');
    if (!funeral.Surname) fields.push('Surname');
    if (!funeral.Gender) fields.push('Gender');
    // if (!funeral.Arrangers || funeral.Arrangers.length < 1) fields.push('Arrangers');
    // if (!funeral.Coordinators || funeral.Coordinators.length < 1) fields.push('Admin Co-ordinators');
    if (!funeral.FuneralDressing || !funeral.FuneralDressing.Status) fields.push('Dressing Instructions');
    const disposal = funeral.Disposal;
    if (!disposal || (!disposal.Date && disposal.CrematedOrBuried !== 'Body Not Recovered')) {
        fields.push('Date Of Committal');
    }
    if (
        !disposal ||
        !disposal.DisposalBookingItems ||
        !disposal.DisposalBookingItems.find(
            e =>
                !!e.Product &&
                !!e.Product.ProductCategories &&
                !!e.Product.ProductCategories.find(ee => ee.URLSegment === 'coffins')
        )
    ) {
        fields.push('Coffin');
    }
    let dates = false;
    if (!!funeral.PlaceOfViewingRequired && funeral.PlaceOfViewingItems.filter(e => !!e.Date).length > 0) {
        dates = true;
    }
    if (!dates) {
        const service = funeral.PlaceOfService;
        const useCommittal = !!service && (service.Type === 'Graveside' || service.Type === 'No Service No Attendance');
        if ((!useCommittal && !(!!service && !!service.Date)) || (!!useCommittal && !(!!disposal && !!disposal.Date)))
            fields.push('Date of Viewing or Date of Service');
    }
    return fields;
};

const styles = ({ spacing }) => ({
    noColumn: { margin: `-${spacing.unit * 3}px ${spacing.unit}px ${spacing.unit * 3}px ${spacing.unit}px` },
    column: {
        '& > *': {
            marginBottom: -spacing.unit * 1.5
        }
    },
    placeOfDeathHeader: {
        display: 'flex',
        marginBottom: -24,
        alignItems: 'flex-end'
    },
    alignWithFloatingLabel: {
        marginTop: 8
    },
    placeOfDeathLabel: {
        flex: '1 1 auto'
    },
    pensionGroup: {
        display: 'flex'
    },
    pensionRadio: {
        flex: '0 1 auto'
    },
    pensionSelect: {
        flex: '1 0 auto',
        maxWidth: 180
    },
    retPenRadioLabel: {
        minWidth: 70
    },
    aboTorRadioLabel: {
        flex: '1 1 auto',
        maxWidth: 200
    },
    aboTorRadioGroup: {
        flex: '0 0 auto'
    },
    attachment: {
        clear: 'left'
    },
    attachmentText: {
        float: 'left',
        display: 'inline',
        paddingTop: 10,
        lineHeight: 3
    },
    attachmentButton: {
        float: 'left',
        display: 'inline'
    },
    peeGap: {
        marginTop: '0.88rem'
    }
});

export default withStyles(styles)(Deceased);
