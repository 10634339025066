import Client from './Client';
import ClientFragment from './ClientFragment';
import { joinDefined, titleCase } from '../../../util/strings';
import { validationHelper } from '../../../util/validation';
import { isNullOrUndefined } from '../../../util/objects';
import moment from 'moment';

export default {
    label: 'Client',
    component: Client,
    fragment: ClientFragment,
    onLoad: data => {
        data.ClientChangesFromPrePlan = JSON.parse(data.ClientChangesFromPrePlan) || [];
    },
    formatSaveData: (saveData, state) => {
        if (!state.DateOfBirth) {
            saveData.DateOfBirth = null;
        }

        if (saveData.Occupation) saveData.Occupation = titleCase(saveData.Occupation);
        if (saveData.FirstName) saveData.FirstName = titleCase(saveData.FirstName);
        if (saveData.MiddleName) saveData.MiddleName = titleCase(saveData.MiddleName);
        if (saveData.Surname) saveData.Surname = titleCase(saveData.Surname);
        saveData.ClientChangesFromPrePlan = JSON.stringify(saveData.ClientChangesFromPrePlan || []);
    },
    validation: {
        onValidate: {
            FirstName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'FirstName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            MiddleName: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'MiddleName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            Surname: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'Surname';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            FamilyNameAtBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'FamilyNameAtBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            DateEnteredAustralia: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'DateEnteredAustralia';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                const placeOfBirthCountry = getField('PlaceOfBirthCountry');
                const placeOfBirth1 = getField('PlaceOfBirth1');
                const dateEnteredUnknown = getField('DateEnteredAustraliaUnknown');

                if (placeOfBirthCountry === 'Australia' || placeOfBirth1 === 'Unknown' || dateEnteredUnknown) {
                    return validationHelper.ok();
                }

                if (!isNullOrUndefined(persistedValue)) {
                    const value = parseInt(persistedValue, 10);
                    const max = new Date().getFullYear();
                    const min = max - 120;
                    return value < min || value > max
                        ? validationHelper.required(`Must be between ${min} and ${max}`)
                        : validationHelper.ok();
                }

                return validationHelper.suggested();
            },
            Gender: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'Gender';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            Occupation: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'Occupation';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            Pensioner: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'Pensioner';
                const typeFieldName = 'PensionType';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Change value to: ' +
                            (changes[fieldName] === 'Y' ? 'Yes' : 'No') +
                            (changes[typeFieldName] !== undefined ? ' (' + changes[typeFieldName] + ')' : '')
                    );
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            PensionType: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan');
                const fieldName = 'PensionType';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (hasValue) return validationHelper.ok();
                return validationHelper.suggested();
            },
            DateOfBirth: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const fieldName = 'DateOfBirth';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional(
                        'Change value to: ' + moment(changes[fieldName]).format('YYYY-MM-DD')
                    );
                }
                if (getField('DateOfDeath1') < persistedValue)
                    return validationHelper.required('Must not be after date of death.');
                if (getField('UnknownDOB') === true || !isNullOrUndefined(persistedValue)) return validationHelper.ok();

                return validationHelper.required();
            },
            PlaceOfBirth1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const city = 'PlaceOfBirth1';
                const state = 'PlaceOfBirthState';
                const country = 'PlaceOfBirthCountry';
                if (changes[city] !== undefined || changes[state] !== undefined || changes[country] !== undefined) {
                    return validationHelper.optional(
                        'Change value to: ' + joinDefined([changes[city], changes[state], changes[country]], ' ')
                    );
                }
                return validationHelper.ok();
            },
            ResidentialAddress1: (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('ClientChangesFromPrePlan') || {};
                const street = 'ResidentialAddress1';
                const city = 'ResidentialTown';
                const state = 'ResidentialState';
                const country = 'ResidentialCountry';
                const postcode = 'PostCode';
                if (
                    changes[street] !== undefined ||
                    changes[city] !== undefined ||
                    changes[state] !== undefined ||
                    changes[country] !== undefined ||
                    changes[postcode] !== undefined
                ) {
                    return validationHelper.optional(
                        'Change value to: ' +
                            joinDefined(
                                [changes[street], changes[city], changes[postcode], changes[state], changes[country]],
                                ' '
                            )
                    );
                }
                return validationHelper.ok();
            }
        }
    }
};
