import gql from 'graphql-tag';
import { isRelatedObjectDefined } from '../../util/bookable';
import { COMPONENT } from '../../component/form/AddressAutocomplete';

export const sanitizeAppointment = ({ input }) => {
    let appointment = {
        input: {
            ID: input.ID,
            Date: input.Date,
            DateTo: input.DateTo,
            TimeFrom: input.TimeFrom,
            TimeTo: input.TimeTo,
            Reason: input.Reason,
            AllDay: input.AllDay,
            Recuring: input.Recuring,
            Office: input.Office,
            LocationType: input.LocationType,
            Phone: input.Phone,
            Comment: input.Comment,
            Type: input.Type,
            Members: input.Members.map(({ ID }) => {
                return { ID };
            })
        }
    };
    if (input.Type !== 'Unavailable') {
        if (!!input.Enquiry) {
            appointment.input.EnquiryID = input.Enquiry.ID;
        }
        if (!!input.Funeral) {
            appointment.input.FuneralID = input.Funeral.ID;
        }
    } else {
        appointment.input.EnquiryID = null;
        appointment.input.FuneralID = null;
    }
    if (!!input.Location && input.LocationType === 'AddressBook' && !!input.Location.ID) {
        appointment.input.LocationID = input.Location.ID;
    } else if (input.LocationType === 'Residential') {
        appointment.input.LocationResidentialAddress = { ...input.LocationResidentialAddress };
        delete appointment.input.LocationResidentialAddress.ID; // creates duplicates but prevents overwrites
    }

    return appointment;
};

export const sanitizeCalendarEvent = ({ input }) => {
    const output = {
        ID: input.ID,
        ClassName: input.ClassName,
        Start: input.Start,
        End: input.End,
        Title: input.Title,
        Notes: input.Notes
        //StaffAllocations: null //input.StaffAllocations.map(x => sanitizeStaffAllocation({ input: x }).input)
    };
    if (!!input.Location && !!input.Location.ID) {
        output.LocationID = input.Location.ID;
    }
    return { input: output };
};

export const sanitizeCalendarAllocations = ({ input }) => {
    return {
        input: {
            ID: input.ID,
            ClassName: input.ClassName,
            StaffAllocations: input.StaffAllocations.map(x => sanitizeStaffAllocation({ input: x }).input)
        }
    };
};

export const sanitizeStaffAllocation = ({ input }) => {
    let sanitized = {
        ID: input.ID,
        Title: input.Title,
        Allocation: input.Allocation,
        Type: input.Type,
        Start: `${input.DateFrom} ${input.TimeFrom}`,
        End: `${input.DateTo} ${input.TimeTo}`,
        Location: input.Location,
        Comments: input.Comments,
        Recurring: input.Recurring,
        MemberID: input.Member ? input.Member.ID : null
    };

    if (isRelatedObjectDefined(input.Funeral)) {
        sanitized.FuneralID = input.Funeral.ID;
        if (input.Type === 'Viewing' && isRelatedObjectDefined(input.Viewing, 'Location')) {
            sanitized.ViewingID = input.Viewing.ID;
        } else if (input.Type === 'Service' && isRelatedObjectDefined(input.Service, 'Location')) {
            sanitized.ServiceID = input.Service.ID;
        } else if (
            ['Committal', 'Disposal'].includes(input.Type) &&
            isRelatedObjectDefined(input.Disposal, 'Location')
        ) {
            sanitized.DisposalID = input.Disposal.ID;
        } else if (input.Type === 'Refreshments' && isRelatedObjectDefined(input.Refreshments, 'Location')) {
            sanitized.RefreshmentsID = input.Refreshments.ID;
        }
    } else if (input.FuneralID) {
        sanitized.FuneralID = input.FuneralID;
    }

    return { input: sanitized };
};

export const sanitizeCateringStaffAllocation = (RefreshmentID, input) => {
    let sanitized = {
        ID: input.ID,
        Start: `${input.DateFrom} ${input.TimeFrom}`,
        End: `${input.DateTo} ${input.TimeTo}`,
        Availability: input.Availability,
        Confirmed: input.Confirmed,
        AvailabilityDescription: input.AvailabilityDescription,
        Points: input.Points ? input.Points : 1,
        CateringStaffID: input.CateringStaffID ? input.CateringStaffID : null,
        RefreshmentsID: RefreshmentID
    };

    return sanitized;
};

export const sanitizeAndAppendFuneralData = (Type, FuneralID, EventID, staffAllocation) => {
    let sanitized = sanitizeStaffAllocation({
        input: {
            ...staffAllocation,
            FuneralID,
            Type,
            Title: staffAllocation.Member
                ? staffAllocation.Member.FirstName + ' ' + staffAllocation.Member.Surname
                : null
        }
    }).input;

    //move the type. this can't happen in the nested func above
    if (Type === 'Viewing') {
        sanitized.ViewingID = EventID;
    } else if (Type === 'Service') {
        sanitized.ServiceID = EventID;
    } else if (['Committal', 'Disposal'].includes(Type)) {
        sanitized.DisposalID = EventID;
    } else if (Type === 'Refreshments') {
        sanitized.RefreshmentsID = EventID;
    } else {
        throw new Error('unknown staff appointment type ' + Type);
    }

    delete sanitized.Location;

    return sanitized;
};

const AppointmentFragment = gql`
    fragment Appointment on Appointment {
        ID
        Date
        DateTo
        TimeFrom
        TimeTo
        Reason
        Type
        AllDay
        Recuring
        Office
        Comment
        Phone
        Funeral {
            ID
            LegacyKey
            FirstName
            Surname
        }
        Enquiry {
            ID
            LegacyKey
            EnquirerGivenName
            EnquirerSurname
            NameOfDeceased
        }
        LocationType
        Location {
            ID
            Name
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        LocationResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
        }
        Members {
            ID
            FirstName
            Surname
        }
    }
`;

const FuneralFragment = gql`
    fragment Funeral on Funeral {
        ID
        LegacyKey
        Surname
        FirstName
        MiddleName
        PlaceOfService {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
        PlaceOfViewingItems {
            ID
            Code
            Start
            End
            Location {
                ID
                Name
            }
        }
        Disposal {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
        RefreshmentsVenue {
            ID
            Date
            Start
            End
            Location {
                ID
                Name
            }
        }
        PlaceOfViewingItems {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
    }
`;

const StaffAllocationFragment = gql`
    ${FuneralFragment}
    ${AppointmentFragment}
    fragment StaffAllocation on StaffAllocation {
        ID
        Title
        Allocation
        Start
        End
        Type
        Location
        Comments
        Recurring
        Appointment {
            ...Appointment
        }
        Member {
            ID
            FirstName
            Surname
            Email
        }
        Funeral {
            ...Funeral
        }
        Service {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
        Viewing {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
        Disposal {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
        Refreshments {
            ID
            Start
            End
            Location {
                ID
                Name
            }
        }
    }
`;

const CalendarEventFragment = gql`
    fragment CalendarEvent on CalendarEvent {
        ID
        ClassName
        Title
        Start
        End
        Notes
        Location {
            ID
            Name
            Suburb
            State
        }

        EventConflicts {
            nodes {
                ... on FuneralPlaceOfService {
                    ID
                    Confirmed
                }
                ... on FuneralPlaceOfViewing {
                    ID
                    Confirmed
                }
                ... on FuneralDisposal {
                    ID
                    Confirmed
                }
                ... on RefreshmentsVenue {
                    ID
                    Confirmed
                }
                ... on CalendarEvent {
                    ID
                }
            }
        }
    }
`;

export const createAppointmentMutation = gql`
    ${AppointmentFragment}
    mutation($input: CreateAppointmentInput!) {
        createAppointment(input: $input) {
            ...Appointment
        }
    }
`;

export const updateAppointmentMutation = gql`
    ${AppointmentFragment}
    mutation($input: UpdateAppointmentInput!) {
        updateAppointment(input: $input) {
            ...Appointment
        }
    }
`;

export const createStaffAllocationMutation = gql`
    ${StaffAllocationFragment}
    mutation($input: CreateStaffAllocationInput!) {
        createStaffAllocation(input: $input) {
            ...StaffAllocation
        }
    }
`;

export const updateStaffAllocationMutation = gql`
    ${StaffAllocationFragment}
    mutation($input: UpdateStaffAllocationInput!) {
        updateStaffAllocation(input: $input) {
            ...StaffAllocation
        }
    }
`;

export const createCalendarEventMutation = gql`
    ${CalendarEventFragment}
    mutation($input: CreateCalendarEventInput!) {
        createCalendarEvent(input: $input) {
            ...CalendarEvent
        }
    }
`;

export const updateCalendarEventMutation = gql`
    ${CalendarEventFragment}
    mutation($input: UpdateCalendarEventInput!) {
        updateCalendarEvent(input: $input) {
            ...CalendarEvent
        }
    }
`;

export const readAllocatableFuneralLocations = gql`
    ${FuneralFragment}
    query readOneFuneral($id: ID!) {
        readOneFuneral(ID: $id) {
            ...Funeral
        }
    }
`;

export const readOneAppointmentQuery = gql`
    ${AppointmentFragment}
    query readOneAppointment($id: ID!) {
        readOneAppointment(ID: $id) {
            ...Appointment
        }
    }
`;

export const readOneStaffAllocationQuery = gql`
    ${StaffAllocationFragment}
    query readOneStaffAllocation($id: ID!) {
        readOneStaffAllocation(ID: $id) {
            ...StaffAllocation
        }
    }
`;

export const searchCollidingStaffAllocations = gql`
    query searchCollidingStaffAllocations($from: String!, $to: String!) {
        readStaffAllocations(from: $from, to: $to) {
            ID
            Appointment {
                ID
            }
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

export const readOneCalendarEventQuery = gql`
    query readOneCalendarEvent($id: ID!) {
        readOneCalendarEvent(ID: $id) {
            ... on CalendarEvent {
                ID
                ClassName
                Title
                Start
                End
                Notes
                Location {
                    ID
                    Name
                    Suburb
                    State
                }
                StaffAllocations {
                    ID
                    Allocation
                    Type
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                EventConflicts {
                    nodes {
                        ... on FuneralPlaceOfService {
                            ID
                            Confirmed
                        }
                        ... on FuneralPlaceOfViewing {
                            ID
                            Confirmed
                        }
                        ... on FuneralDisposal {
                            ID
                            Confirmed
                        }
                        ... on RefreshmentsVenue {
                            ID
                            Confirmed
                        }
                        ... on CalendarEvent {
                            ID
                        }
                    }
                }
            }
            ... on FuneralDisposal {
                ID
                ClassName
                Title
                Start
                End
                Location {
                    ID
                    Name
                    Suburb
                }
                StaffAllocations {
                    ID
                    Allocation
                    Type
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
            }
            ... on RefreshmentsVenue {
                ID
                ClassName
                Title
                Start
                End
                Location {
                    ID
                    Name
                    Suburb
                }
                StaffAllocations {
                    ID
                    Allocation
                    Type
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
            }
            ... on FuneralPlaceOfService {
                ID
                ClassName
                Title
                Start
                End
                Location {
                    ID
                    Name
                    Suburb
                }
                StaffAllocations {
                    ID
                    Allocation
                    Type
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
            }
            ... on FuneralPlaceOfViewing {
                ID
                ClassName
                Title
                Start
                End
                Location {
                    ID
                    Name
                    Suburb
                }
                StaffAllocations {
                    ID
                    Allocation
                    Type
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Start
                    End
                }
                Funeral {
                    ID
                    LegacyKey
                    FirstName
                    Surname
                }
            }
        }
    }
`;

export const APPOINTMENT_RESIDENTIAL_COMPONENTS = {
    [COMPONENT.line1]: 'appointment.LocationResidentialAddress.AddressLine1',
    [COMPONENT.line2]: 'appointment.LocationResidentialAddress.AddressLine2',
    [COMPONENT.city]: 'appointment.LocationResidentialAddress.Suburb',
    [COMPONENT.state]: 'appointment.LocationResidentialAddress.State',
    [COMPONENT.code]: 'appointment.LocationResidentialAddress.Postcode',
    [COMPONENT.country]: 'appointment.LocationResidentialAddress.Country'
};
