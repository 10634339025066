import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { Card, withStyles } from '@material-ui/core';
import Grid from '../../component/form/Grid';
import PhotoModal from '../../component/form/PhotoModal';
import Paper from '@material-ui/core/Paper/Paper';
import Spinner from '../../component/form/v2/Spinner';
import Typography from '@material-ui/core/Typography/Typography';
import BiggerMessageBar from '../../component/BiggerMessageBar';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../component/form/Table';
import PrimaryButton, { OutlineButton, SaveButton } from '../../component/form/PrimaryButton';
import DataFormView from '../../component/form/v2/DataFormView';
import cx from 'classnames';
import { getDressingStatus, MortuaryItemFragment, releaseToCheckout } from './MortuaryItemConstants';
import { isNullOrUndefined } from '../../util/objects';
import ColumnLayout from '../../component/ColumnLayout';
import { flattenBookingItems, PreloadProducts, ProductConfig, QueryKeys } from '../../util/products';
import { ImageList, ImageListItem } from '../../component/form/ImageList';
import CoffinModal from './Common/CoffinModal';
import { joinDefined, prettyDisposal, stringIsNullOrEmpty } from '../../util/strings';
import ValuablesForm from './Common/ValuablesForm';
import IdentificationForm from './Common/IdentificationForm';
import history from '../../History';
import moment from 'moment';
import { InlineField, InlineFieldRightAlignChildren } from '../../component/form/Inline';
import TickCircleIcon from '../../component/icon/TickCircleIcon';
import { isContactDefined } from '../../util/bookable';
import GatedComponent from '../../component/GatedComponent';
import { niceDateTimeFromString } from '../../util/date';
import NextIcon from '../../component/icon/NextIcon';
import BackIcon from '../../component/icon/BackIcon';
import {
    getRequiredDocuments,
    getSignedArrangementFormForDocument
} from '../funeral/certification/CertificationConstants';
import LinkButton from '../../component/form/LinkButton';
import ExclamationTriangleIcon from '../../component/icon/ExclamationTriangleIcon';
import TickIcon from '../../component/icon/TickIcon';
import CloseIcon from '../../component/icon/CloseIcon';
import RecordDrawer from '../workQueue2/RecordDrawer';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class MortuaryItem extends Component {
    state = {
        loading: false,
        loadingProducts: true,
        openCoffinModal: false,
        canScanCoffinCode: true,
        selectedPhoto: null
    };

    componentDidMount() {
        const { form } = this.props;
        const coffinProducts = form.getState('Disposal.DisposalBookingItems') || [];
        const flowerProducts = form.getState('Florists').map(e => e.BookingItems || []) || [];

        const products = coffinProducts.concat(
            flowerProducts.length > 0 ? flowerProducts.reduce((a, b) => a.concat(b)) : []
        );

        if (products && products.length > 0) {
            PreloadProducts(this, products).then(() => this.setState({ loadingProducts: false }));
        } else {
            this.setState({ loadingProducts: false });
        }
    }

    render() {
        const { classes, form } = this.props;
        const { openCoffinModal, loading, selectedPhoto } = this.state;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <InlineFieldRightAlignChildren lineHeight={'inherit'}>
                        {form.getField('Cancelled') && (
                            <BiggerMessageBar messageType="error">This record is cancelled.</BiggerMessageBar>
                        )}
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Mortuary</span>
                            <span className={classes.header}>
                                Action:{' '}
                                {`${form.getState('LegacyKey')} ${joinDefined(
                                    [form.getState('FirstName'), form.getState('MiddleName'), form.getState('Surname')],
                                    ' '
                                )}`}
                            </span>
                        </Typography>

                        <PrimaryButton onClick={this.backButtonFunction}>
                            <BackIcon /> Back
                        </PrimaryButton>

                        <OutlineButton
                            target="_blank"
                            onClick={() => this.viewFuneralDetails(form.getState('ID'), form.getState('LegacyKey'))}
                        >
                            View Funeral Record
                        </OutlineButton>
                        <RecordDrawer funeralId={form.getField('ID')} legacyKey={form.getField('LegacyKey')} />
                    </InlineFieldRightAlignChildren>
                    <br />
                </Grid>

                <Paper
                    className={cx(classes.paper, classes.bodyPadding)}
                    elevation={0}
                    style={{ position: 'relative' }}
                >
                    {!!loading && this.renderLoading()}
                    <ColumnLayout>
                        {this.renderLeftColumn()}
                        {this.renderRightColumn()}
                    </ColumnLayout>
                </Paper>

                <CoffinModal
                    context={this}
                    itemId={form.getState('ID')}
                    open={openCoffinModal}
                    onClose={() => this.setState({ openCoffinModal: false })}
                    onSaved={funeral => this.onChangeCoffin(funeral)}
                />

                <PhotoModal photo={selectedPhoto} onClose={() => this.setState({ selectedPhoto: null })} />
            </Grid>
        );
    }

    renderLeftColumn() {
        const { classes } = this.props;

        return (
            <Grid container spacing={24} className={classes.column} style={{ position: 'relative' }}>
                {this.renderChangelist()}

                {this.renderTopTextFields()}

                {this.renderPhotos()}

                {this.renderFlorists()}
            </Grid>
        );
    }

    renderRightColumn() {
        const { classes, form } = this.props;
        const { loading } = this.state;
        const isComplete = form.getField('DatetimeOfMortuaryCheckout');
        const isReleased = form.getField('DatetimeReadyForMortuaryCheckout');
        const isReturned = form.getField('MortuaryActionReturnedReason');

        return (
            <Grid container spacing={24} className={classes.column} style={{ position: 'relative' }}>
                {this.renderCoffinDetails()}

                {this.renderValuablesTable()}

                {this.renderRequiredDocuments()}

                <Grid bucket>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Release to Mortuary Checkout</span>
                        </Typography>

                        {(!!isReleased && (
                            <Typography variant="subheading">
                                Released to Mortuary Checkout on {niceDateTimeFromString(isReleased)}
                                {!!isComplete && <div>Checkout completed on {niceDateTimeFromString(isComplete)}</div>}
                            </Typography>
                        )) ||
                            (!!isReturned && <BiggerMessageBar messageType="error">{isReturned}</BiggerMessageBar>) || (
                                <Typography variant="subheading">Waiting for Mortuary staff to release.</Typography>
                            )}
                    </Grid>
                    <InlineField>
                        <IdentificationForm form={form} />
                        {(!isReleased && (
                            <GatedComponent showComponentCode={'FM_ACCESS_Mortuary_Process'}>
                                {() => (
                                    <SaveButton disabled={loading} onClick={() => this.handleReleaseToCheckout()}>
                                        <TickCircleIcon />
                                        Release to checkout
                                    </SaveButton>
                                )}
                            </GatedComponent>
                        )) || (
                            <OutlineButton onClick={e => this.goCheckoutFunction()}>
                                <NextIcon /> Go to Checkout
                            </OutlineButton>
                        )}
                    </InlineField>
                </Grid>
            </Grid>
        );
    }

    renderLoading() {
        return (
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: '#FFFFFF99',
                    margin: -24,
                    zIndex: 9
                }}
            >
                {this.renderLoadingWidget()}
            </div>
        );
    }

    renderLoadingWidget() {
        return (
            <div>
                <Spinner />
                &nbsp;<span>Loading...</span>
            </div>
        );
    }

    renderPhotos() {
        const { form, classes } = this.props;
        const photos = form.getField('DeceasedPhotos') || [];

        return (
            <Grid bucket={true} container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        <span>Photos</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {photos.length > 0 ? (
                        <ImageList>
                            {photos.map((photo, i) => (
                                <ImageListItem
                                    key={i}
                                    value={{ image: photo.AbsoluteLink, label: photo.Name }}
                                    onClick={() => this.setState({ selectedPhoto: photo })}
                                />
                            ))}
                        </ImageList>
                    ) : (
                        <Typography variant="subheading">No photos supplied.</Typography>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderValuablesTable() {
        const { classes, form } = this.props;
        const valuables = form.getState('ValuableItems') || [];

        return (
            <GatedComponent
                showComponentCode={'FM_ACCESS_Mortuary_Valuables_View'}
                isEnabledCode={'FM_ACCESS_Mortuary_Valuables_Edit'}
            >
                {isEnabled => {
                    return (
                        <Grid bucket={true} xs={12}>
                            <Grid item xs={12}>
                                <Typography variant={'headline'} className={cx(classes.pageTitle)}>
                                    Valuables
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.tableData}>
                                    {valuables.length > 0 ? (
                                        <Table>
                                            <TableHeaderRow>
                                                <TableHeaderCell>Description</TableHeaderCell>
                                                <TableHeaderCell>QTY</TableHeaderCell>
                                                <TableHeaderCell>Placement</TableHeaderCell>
                                                <TableHeaderCell>Status</TableHeaderCell>
                                                <TableHeaderCell>Location</TableHeaderCell>
                                                <TableHeaderCell>Return</TableHeaderCell>
                                                <TableHeaderCell>Done</TableHeaderCell>
                                            </TableHeaderRow>
                                            {valuables.map((valuable, index) => (
                                                <TableRow key={index} pad={true}>
                                                    <TableCell>{valuable.Description}</TableCell>
                                                    <TableCell>{valuable.Quantity}</TableCell>
                                                    <TableCell>{valuable.ToBePlaced}</TableCell>
                                                    <TableCell>{valuable.CurrentLocation}</TableCell>
                                                    <TableCell>{valuable.CurrentLocationDetail}</TableCell>
                                                    <TableCell>{valuable.ToBeReturned ? 'Yes' : 'No'}</TableCell>
                                                    <TableCell centred>
                                                        {valuable.ToBeReturned ? (
                                                            valuable.CurrentLocation === 'Returned' ? (
                                                                <TickIcon color={'disabled'} />
                                                            ) : (
                                                                <CloseIcon color={'error'} />
                                                            )
                                                        ) : valuable.CurrentLocation !== 'Awaiting Delivery' ? (
                                                            <TickIcon color={'disabled'} />
                                                        ) : (
                                                            <CloseIcon color={'error'} />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    ) : (
                                        <Typography variant="subheading">
                                            Deceased has no record of valuables.
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <InlineField>
                                <ValuablesForm form={form} />
                            </InlineField>
                        </Grid>
                    );
                }}
            </GatedComponent>
        );
    }

    renderCoffinDetails() {
        const { loadingProducts } = this.state;
        const changes = this.getChangelist();
        const { form, classes } = this.props;
        const coffinProducts = (form.getField('Disposal.DisposalBookingItems') || []).map(b => {
            const obj = { ...b };
            const product = ProductConfig.productMap[obj.ProductID];
            if (!!product) {
                if (obj.Quantity && product.urlSegment === 'coffins') product.tileWidth = 2;
                obj.titleObj = product.variations.find(e => e.id === obj.VariationID) || product;
            }
            return { ...obj, product };
        });
        const isComplete = form.getField('DatetimeOfMortuaryCheckout');
        const isReleased = form.getField('DatetimeReadyForMortuaryCheckout');
        const canScanCoffinCode =
            !(isComplete || isReleased) && coffinProducts.filter(x => x.URLSegment === QueryKeys.Coffins).length > 0;
        const coffinsChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'coffins');
        return (
            <Grid container bucket={true}>
                <Grid item xs={12}>
                    <Typography
                        variant="headline"
                        gutterBottom
                        className={cx(classes.pageTitle, classes.coffinDetailsHeader)}
                    >
                        <span>Coffin Details</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {loadingProducts ? (
                        this.renderLoadingWidget()
                    ) : coffinProducts.length > 0 ? (
                        <Fragment>
                            <ImageList>
                                {coffinProducts.map((obj, i) => {
                                    return <ImageListItem key={i} value={obj.product} quantity={obj.Quantity} />;
                                })}
                            </ImageList>
                            <ul>
                                {coffinProducts.map((obj, i) => {
                                    return (
                                        <li key={i} className={Number(obj.Quantity) === 0 ? classes.strikeout : ''}>
                                            {Number(obj.Quantity) === 0 ? '' : Number(obj.Quantity) + 'x'}{' '}
                                            <strong>{obj.titleObj.internalId}</strong>: {obj.titleObj.title}
                                            {1 === 2 && !!obj.Returns ? ' | To be returned' : ''}
                                            {!!obj.Comment ? ' | Comment: ' + obj.Comment : ''}
                                        </li>
                                    );
                                })}
                            </ul>
                        </Fragment>
                    ) : (
                        <Typography variant="subheading">No coffins have been selected.</Typography>
                    )}

                    {!!coffinsChanged && (
                        <BiggerMessageBar messageType="error">
                            <strong>Changed:</strong> {coffinsChanged.oldVal}
                        </BiggerMessageBar>
                    )}
                </Grid>
                <GatedComponent showComponentCode={'FM_ACCESS_Mortuary_Process'}>
                    {() => {
                        return (
                            <Grid item xs style={{ marginBottom: 15 }}>
                                <PrimaryButton
                                    onClick={() => this.setState({ openCoffinModal: true })}
                                    disabled={!canScanCoffinCode}
                                >
                                    Scan Coffin Barcode...
                                </PrimaryButton>
                            </Grid>
                        );
                    }}
                </GatedComponent>
            </Grid>
        );
    }

    renderTopTextFields() {
        const { form, classes } = this.props;
        const changes = this.getChangelist();

        let latestDate = moment().add(100, 'years');
        let viewing = null;
        const items = form.getState('PlaceOfViewingItems') || [];
        items.forEach(e => {
            const theDate = moment(e.Start || e.Date);
            if (moment(theDate).isValid() && theDate < latestDate) {
                latestDate = theDate;
                viewing = e;
            }
        });
        const isGraveside = form.getField('PlaceOfService.Type') === 'Graveside';
        const service = !!isGraveside ? form.getState('Disposal') : form.getState('PlaceOfService');
        const getRelease = () => {
            const startV = !!viewing && (viewing.Start || viewing.Date);
            const startS = !!service && (service.Start || service.Date);
            if (startV && !startS) return viewing;
            if (startS && !startV) return service;
            return moment(startV).isBefore(startS) ? viewing : service;
        };
        const release = getRelease();
        const isCremation = form.getState('Disposal.CrematedOrBuried') === 'Cremated';
        const hasPacemaker = !!isCremation && !!form.getState('RemovePacemaker');
        const needsReferee = !!isCremation && !(form.getState('Certification.TypeOfBdmDeathCertificate') === 'Coroner');
        const isCrypt =
            (form.getState('Disposal.CrematedOrBuried') === 'Buried' ||
                form.getState('Disposal.CrematedOrBuried') === 'Repatriated') &&
            (form.getState('Grave.GroundDescription') === 'Crypt' || form.getState('Grave.DepthOption') === 'Crypt');

        const serviceChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'service');
        const viewingChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'viewing');
        const releaseChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'release');
        const dressingChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'dressing');
        const batteryChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'pacemaker');
        const genderChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'gender');
        const surnameChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'surname');
        const firstnameChanged = !!changes && changes.length !== 0 && changes.find(e => e.field === 'firstname');
        return (
            <Fragment>
                <Grid container bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>{prettyDisposal(form.getState('Disposal.CrematedOrBuried'), false)}</span>
                            {!!isCremation && (
                                <span className={classes.lightGreyHeader}>
                                    {!!needsReferee ? (
                                        !!isContactDefined(form.getState('Certification.Referee')) ? (
                                            !!form.getState('RefereeCremationCheck') ? (
                                                <Fragment>
                                                    Referee Completed{' '}
                                                    <TickIcon className={classes.icon} title={'Completed'} />
                                                </Fragment>
                                            ) : (
                                                <Fragment>Referee Assigned</Fragment>
                                            )
                                        ) : (
                                            <span className={classes.redText}>Referee Not Assigned</span>
                                        )
                                    ) : (
                                        <Fragment>Referee Not Required</Fragment>
                                    )}
                                </span>
                            )}
                            {!!isCrypt && <span className={classes.lightGreyHeader}>for Crypt</span>}
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="subheading">
                            Viewing:{' '}
                            <span className={classes.purpleText}>
                                {!!form.getField('PlaceOfViewingRequired') && !isNullOrUndefined(viewing)
                                    ? !!viewing.Date && !!viewing.Time
                                        ? niceDateTimeFromString(viewing.Date + ' ' + viewing.Time)
                                        : 'Date/time not provided.'
                                    : 'No viewings.'}
                            </span>
                        </Typography>
                        {!!viewingChanged && this.renderChangeItem(viewingChanged)}
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="subheading">
                            Service:{' '}
                            <span className={classes.purpleText}>
                                {!isNullOrUndefined(service) && service.Type !== 'No Service No Attendance'
                                    ? !!service.Date && !!service.Time
                                        ? niceDateTimeFromString(service.Date + ' ' + service.Time)
                                        : 'Date/time not provided.'
                                    : 'No service.'}
                            </span>
                        </Typography>
                        {!!serviceChanged && this.renderChangeItem(serviceChanged)}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subheading">
                            Release to:{' '}
                            {!isNullOrUndefined(release) && !!isContactDefined(release.Location) ? (
                                <span className={classes.purpleText}>{release.LocationFlattened}</span>
                            ) : (
                                'No release specified.'
                            )}
                        </Typography>
                        {!!releaseChanged && this.renderChangeItem(releaseChanged)}
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Dressing</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subheading">{getDressingStatus(form)}</Typography>
                        {!!dressingChanged && this.renderChangeItem(dressingChanged)}
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Mortuary Preparation Notes</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subheading" className={classes.notesPre}>
                            {form.getState('CoffinComment') || 'No notes.'}
                        </Typography>

                        {!!isCrypt && (
                            <BiggerMessageBar messageType="warning">Embalming is required for Crypt.</BiggerMessageBar>
                        )}

                        {!!hasPacemaker && (
                            <BiggerMessageBar messageType="warning">Pacemaker must be removed.</BiggerMessageBar>
                        )}
                        {!!batteryChanged && (
                            <Fragment>
                                {!hasPacemaker && (
                                    <BiggerMessageBar messageType="info">
                                        Pacemaker does not need removal.
                                    </BiggerMessageBar>
                                )}
                                {!!batteryChanged && this.renderChangeItem(batteryChanged)}
                            </Fragment>
                        )}

                        {!!genderChanged && this.renderChangeItem(genderChanged, 'Gender')}
                        {!!surnameChanged && this.renderChangeItem(surnameChanged, 'Surname')}
                        {!!firstnameChanged && this.renderChangeItem(firstnameChanged, 'First Name')}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderChangeItem(changeItem, thing = 'data') {
        return (
            <BiggerMessageBar messageType="error">
                {(!!changeItem.oldVal && (
                    <Fragment>
                        <strong>The {thing} has been changed from:</strong> {changeItem.oldVal}
                    </Fragment>
                )) || <strong>There was no previous value set {thing !== 'data' ? ` for ${thing}` : ''}</strong>}
            </BiggerMessageBar>
        );
    }

    renderChangelist() {
        const { form } = this.props;
        const result = [];
        if (!!form.getField('DatetimeReadyForMortuaryCheckout')) {
            result.push(
                <BiggerMessageBar key={0}>This item has already been released to Mortuary Checkout.</BiggerMessageBar>
            );
        } else {
            if (!form.getField('DateTransferredToMortuary'))
                result.push(
                    <BiggerMessageBar messageType="warning" key={0}>
                        Is Not Ready for Mortuary, but forced into Mortuary anyway.
                    </BiggerMessageBar>
                );

            const changes = this.getChangelist();
            if (!(!changes || changes.length === 0))
                result.push(
                    <BiggerMessageBar messageType="error" key={1}>
                        The data has been updated, please check again:
                        <ul style={{ margin: 0 }}>
                            {changes.map((e, i) => (
                                <li key={i}>
                                    <strong>{e.message}</strong>
                                </li>
                            ))}
                        </ul>
                    </BiggerMessageBar>
                );
        }
        return result.length > 0 ? <div style={{ marginBottom: -8, width: '100%' }}>{result}</div> : null;
    }

    getChangelist() {
        const changes = [];
        const { form } = this.props;
        const rawData = form.getField('MortuaryWatchData');
        if (form.getField('DatetimeReadyForMortuaryCheckout')) return null;
        if (rawData) {
            try {
                JSON.parse(rawData);
            } catch (e) {
                return null;
            }
            const watchList = JSON.parse(rawData || '{}');

            /* check service details */
            const isGraveside = form.getField('PlaceOfService.Type') === 'Graveside';
            const oldServiceObj = (watchList['service'] && Object.values(watchList['service'])) || null;
            const oldService = (oldServiceObj && oldServiceObj[0] && oldServiceObj[0].split('|')) || ['', '', ''];
            const oldDisposalObj = (watchList['disposal'] && Object.values(watchList['disposal'])) || null;
            const oldDisposal = (oldDisposalObj && oldDisposalObj[0] && oldDisposalObj[0].split('|')) || ['', '', ''];
            if (watchList['disposal'] && isGraveside) {
                const newVal = niceDateTimeFromString(form.getField('Disposal.Start'));
                const oldVal = niceDateTimeFromString(oldService[1] === 'Graveside' ? oldDisposal[0] : oldService[0]);
                if (oldVal !== newVal) {
                    changes.push({ field: 'service', message: 'Service details have changed.', oldVal });
                }
            } else if (watchList['service']) {
                const newVal = niceDateTimeFromString(form.getField('PlaceOfService.Start'));
                const oldVal = niceDateTimeFromString(oldService[1] === 'Graveside' ? oldDisposal[0] : oldService[0]);
                if (oldVal !== newVal) {
                    changes.push({ field: 'service', message: 'Service details have changed.', oldVal });
                }
            }

            /* check viewing details */
            const items = form.getField('PlaceOfViewingItems') || [];
            let oldViewing = null;
            let newViewing = null;
            if (watchList['viewings'] || items.length) {
                let newVal = null;
                let oldVal = null;
                if (items.length) {
                    let latestDate = moment().add(100, 'years');
                    items.forEach(e => {
                        const theDate = moment(e.Start || e.Date);
                        if (moment(theDate).isValid() && theDate < latestDate) {
                            latestDate = theDate;
                            newVal = niceDateTimeFromString(theDate);
                            newViewing = { Start: e.Start || e.Date, LocationFlattened: e.LocationFlattened };
                        }
                    });
                }
                if (watchList['viewings']) {
                    const oldList = Object.values(watchList['viewings']) || [];
                    let latestDate = moment().add(100, 'years');
                    oldList.forEach(v => {
                        const obj = v.split('|');
                        const theDate = moment(obj[0]);
                        if (moment(theDate).isValid() && theDate < latestDate) {
                            latestDate = theDate;
                            oldVal = niceDateTimeFromString(theDate);
                            oldViewing = { Start: obj[0], LocationFlattened: obj[2] };
                        }
                    });
                }
                if (oldVal !== newVal) {
                    changes.push({ field: 'viewing', message: 'Viewing details have changed.', oldVal });
                }
            }

            /* check release details */
            let oldRelease =
                oldService[1] === 'Graveside'
                    ? { Start: oldDisposal[0], LocationFlattened: oldDisposal[2] }
                    : { Start: oldService[0], LocationFlattened: oldService[2] };
            if (!!oldViewing && moment(oldRelease.Start).isAfter(moment(oldViewing.Start))) {
                oldRelease = oldViewing;
            }
            let newRelease = (isGraveside ? form.getField('Disposal') : form.getField('PlaceOfService')) || {};
            if (!!newViewing && moment(newRelease.Start).isAfter(moment(newViewing.Start))) {
                newRelease = newViewing;
            }
            if (oldRelease.LocationFlattened !== newRelease.LocationFlattened) {
                const oldVal = oldRelease.LocationFlattened;
                changes.push({ field: 'release', message: 'Release details have changed.', oldVal });
            }

            /* check coffin details */
            if (watchList['coffin']) {
                const items = form.getField('Disposal.DisposalBookingItems') || [];
                let oldVal = '';
                const oldList = Object.values(watchList['coffin']) || [];
                if (oldList.length) {
                    oldList.forEach(thing => {
                        if (thing && typeof thing === 'string') {
                            const obj = thing.split('|');
                            const item = items.find(o => o.InternalItemID === obj[0]);
                            if (!item) {
                                oldVal += obj[2] + ' was removed. ';
                            } else if (Number(item.Quantity) !== Number(obj[1])) {
                                oldVal += obj[2] + ' quantity was x' + obj[1] + '. ';
                            }
                        }
                    });
                }
                if (items.length) {
                    items.forEach(thing => {
                        const obj = oldList.find(
                            o => !!o && typeof o === 'string' && o.startsWith(thing.InternalItemID + '|')
                        );
                        if (!obj) oldVal += thing.Title + ' was added. ';
                    });
                }
                if (!!oldVal) {
                    changes.push({ field: 'coffins', message: 'Coffin details have changed.', oldVal });
                }
            }

            /* check dressing details */
            if (watchList['dressing']) {
                const { Date, Time, Status, GreekShroud, Shroud } = form.getField('FuneralDressing');
                const obj = watchList['dressing'].split('|');
                let oldVal = '';
                const datetime = joinDefined([Date, Time], ' ');
                if (!(obj[1] && obj[1] === Status)) {
                    oldVal += obj[1] || '';
                }
                if (obj[0] !== datetime) {
                    oldVal += niceDateTimeFromString(obj[0]) || '';
                }
                if (!!obj[2]) {
                    oldVal += obj[2].includes('S') && !Shroud ? 'Use Shroud. ' : '';
                    oldVal += obj[2].includes('G') && !GreekShroud ? 'Use Greek Shroud. ' : '';
                } else {
                    oldVal += !!Shroud ? 'No Shroud. ' : '';
                    oldVal += !!GreekShroud ? 'No Greek Shroud. ' : '';
                }
                if (oldVal) changes.push({ field: 'dressing', message: 'Dressing details have changed.', oldVal });
            }

            /* check personal/body details */
            if (watchList['surname'] && watchList['surname'] !== form.getField('Surname')) {
                const oldVal = watchList['surname'];
                changes.push({ field: 'surname', message: 'Deceased Surname has changed.', oldVal });
            }
            if (watchList['firstname'] && watchList['firstname'] !== form.getField('FirstName')) {
                const oldVal = watchList['firstname'];
                changes.push({ field: 'firstname', message: 'Deceased First Name has changed.', oldVal });
            }
            if (watchList['gender'] && watchList['gender'] !== form.getField('Gender')) {
                const oldVal = watchList['gender'];
                changes.push({ field: 'gender', message: 'Deceased Gender has changed.', oldVal });
            }
            if (watchList['pacemaker'] && !!watchList['pacemaker'] !== !!form.getField('RemovePacemaker')) {
                const oldVal = !!watchList['pacemaker']
                    ? 'Pacemaker must be removed.'
                    : 'Pacemaker does not need removal.';
                changes.push({ field: 'pacemaker', message: 'Pacemaker has changed.', oldVal });
            }
        }

        if (changes.length === 0) return null;
        return changes;
    }

    renderFlorists() {
        const { form, classes } = this.props;
        const florists = form.getField('Florists') || [];
        return (
            <Grid bucket={true} container>
                <Grid item xs={12}>
                    <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                        <span>Flowers</span>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {florists.length === 0 ? (
                        <Typography variant="subheading">No Flowers assigned.</Typography>
                    ) : (
                        florists.map((florist, index) => {
                            return (
                                <div key={index}>
                                    <Typography variant="subheading">
                                        Florist:{' '}
                                        <strong>{(florist.Contact && florist.Contact.Name) || '(no supplier)'}</strong>
                                        {!florist.Confirmed && ' (not confirmed)'}
                                    </Typography>
                                    {florist.BookingItems.length > 0 && this.renderFlowerList(florist.BookingItems)}
                                </div>
                            );
                        })
                    )}
                </Grid>
            </Grid>
        );
    }

    renderFlowerList(flowers) {
        const { classes } = this.props;
        if (isNullOrUndefined(flowers)) return;

        return (
            <div>
                <div className={classes.flowerList}>{flowers.map(this.renderFlowerItem)}</div>
                <ul>
                    {flowers.map(value => {
                        const product = ProductConfig.productMap[value.ProductID];
                        if (!product) return null;
                        const titleObj = product.variations.find(e => e.id === value.VariationID) || product;
                        return (
                            <li key={value.ID}>
                                {Number(value.Quantity) === 0 ? '' : Number(value.Quantity) + 'x '}
                                <strong>{titleObj.internalId}</strong> : {titleObj.title}
                                {!!value.Comment ? ' | ' + value.Comment : ''}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    renderFlowerItem = ({ ID, ProductID, VariationID, Quantity }, i) => {
        const { classes } = this.props;
        const product = ProductConfig.productMap[ProductID];
        if (!product) return;
        return (
            <Card key={ID || i} className={classes.flowerItem} elevation={0}>
                <div style={{ backgroundImage: `url(${product.image}` }} className={classes.flowerItemImage} />
                <Typography className={classes.flowerItemName} align="center">
                    {Quantity > 1 && <span className={classes.flowerItemQty}>x{Quantity} </span>}
                    {product.title}
                </Typography>
            </Card>
        );
    };

    renderRequiredDocuments() {
        const { form, classes } = this.props;
        const requiredDocuments = getRequiredDocuments(form);

        return (
            <Grid bucket={true} xs={12}>
                <Grid item xs={12}>
                    <Typography variant={'headline'} className={cx(classes.pageTitle)}>
                        Certification Documents
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.tableData}>
                        <Table>
                            <TableHeaderRow>
                                <TableHeaderCell>Status</TableHeaderCell>
                                <TableHeaderCell>Document Name</TableHeaderCell>
                                <TableHeaderCell>Filename</TableHeaderCell>
                            </TableHeaderRow>
                            {requiredDocuments.map((x, i) => this.renderRequiredDocument(x, i))}
                        </Table>
                    </div>
                </Grid>
            </Grid>
        );
    }

    renderRequiredDocument(requiredDocument, index) {
        const { form, classes } = this.props;

        const document = form.getField(requiredDocument.fieldName) || {
            ID: null,
            AbsoluteLink: null,
            Name: null,
            Size: 0
        };
        const arrangementForm = getSignedArrangementFormForDocument(
            form,
            requiredDocument.fieldName,
            requiredDocument.formType
        );
        const missing =
            stringIsNullOrEmpty(document.AbsoluteLink) &&
            stringIsNullOrEmpty(arrangementForm.PDF && arrangementForm.PDF.AbsoluteLink);

        if (!missing) {
            return (
                <TableRow key={index}>
                    <TableCell>
                        <div className={cx(classes.iconContainer, classes.good)}>
                            <TickCircleIcon />
                        </div>
                    </TableCell>
                    <TableCell>{requiredDocument.description}</TableCell>
                    <TableCell>
                        <LinkButton
                            target="_blank"
                            text={document.Name || (arrangementForm.PDF && arrangementForm.PDF.Name)}
                            onClick={() => window.open(document.AbsoluteLink || arrangementForm.PDF.AbsoluteLink)}
                        />
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <TableRow key={index}>
                <TableCell>
                    <div className={cx(classes.iconContainer, classes.warning)}>
                        <ExclamationTriangleIcon />
                    </div>
                </TableCell>
                <TableCell>{requiredDocument.description}</TableCell>
                <TableCell>Not Uploaded</TableCell>
            </TableRow>
        );
    }

    backButtonFunction() {
        history.push('/mortuary');
    }

    goCheckoutFunction() {
        const { form } = this.props;
        const ID = form.getField('ID');
        const LegacyKey = form.getField('LegacyKey');
        history.push(`/mortuary/checkout/${LegacyKey}/${ID}`);
    }

    viewFuneralDetails = (id, legacyId) => {
        const path = `/funeral/${legacyId}/${id}`;
        const win = window.open(path);
        win.focus();
    };

    handleReleaseToCheckout = e => {
        this.setState({ loading: true });

        const { form } = this.props;
        const me = this;
        releaseToCheckout(form.getState('ID')).then(data => {
            me.props.form.setField(
                {
                    DatetimeReadyForMortuaryCheckout: data.DatetimeReadyForMortuaryCheckout,
                    MortuaryWatchData: data.MortuaryWatchData
                },
                1
            );
            me.setState({ loading: false });
        });
    };

    onChangeCoffin(funeral) {
        const { form } = this.props;

        this.setState({ openCoffinModal: false });

        flattenBookingItems(funeral.Disposal, 'DisposalBookingItems');

        form.setState({
            'Disposal.DisposalBookingItems': funeral.Disposal.DisposalBookingItems
        });

        PreloadProducts(this, funeral.Disposal.DisposalBookingItems);
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3,
        width: '100%'
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    coffinDetailsHeader: {
        '@media (max-width: 1280px)': {
            marginTop: 15
        }
    },
    floatRight: {
        float: 'right'
    },
    bodyPadding: {
        padding: '1.5rem',
        '& p': {
            fontSize: '0.875rem'
        }
    },
    redText: {
        color: 'red'
    },
    column: {
        // padding: '1rem',
        '& > *': {
            padding: '15px',
            marginBottom: 20
        }
    },
    print: {
        '@media print': {
            margin: 100
        }
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    secondaryHeader: {
        color: palette.action.active
    },
    lightGreyHeader: {
        fontWeight: typography.fontWeightLight,
        color: palette.custom.lightishGrey,
        borderLeft: '1px solid ' + palette.custom.lightishGrey,
        paddingLeft: 16,
        marginLeft: 16
    },
    purpleText: {
        // color: palette.action.active
        fontWeight: typography.fontWeightMedium
    },
    tableData: {
        paddingTop: 20
    },
    smallerButton: {
        marginRight: 15,
        '& > span': {
            fontSize: 11
        }
    },
    addButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        backgroundColor: palette.button.alt.backgroundColor,
        borderRadius: 18
    },
    backButtonDesktop: {
        float: 'right',
        top: -45,
        boxShadow: 'none',
        border: '1px solid',
        color: palette.button.alt.backgroundColor,
        backgroundColor: 'white',
        borderRadius: 18,
        marginRight: 15,
        '&:hover': {
            color: 'white'
        }
    },
    notesPre: {
        whiteSpace: 'pre-wrap'
    },
    photoItem: {
        backgroundColor: 'white',
        border: 'lightGrey',
        textAlign: 'center',
        lineHeight: 6
    },
    coffinDetailItem: {
        position: 'relative',
        marginBottom: 10
    },
    coffinDetailImage: {
        width: '90%',
        height: 'auto',
        border: '1px solid lightGrey'
    },
    photoItemInternal: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: 'white',
        width: '90%',
        paddingLeft: 20,
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
        fontSize: 11,
        color: 'black',
        border: '1px solid lightGrey',
        borderTop: '0px'
    },
    familyPhotoGrid: {
        marginTop: 10,
        minHeight: 120
    },
    gridItemFamilyPhoto: {
        position: 'relative'
    },
    photoItemFamily: {
        position: 'relative',
        width: '90%',
        height: 'auto'
    },
    faded: { opacity: 0.5 },
    flowerList: {
        display: 'flex',
        flexWrap: 'wrap'
    },

    flowerItem: {
        flex: '0 0 auto',
        display: 'flex',
        padding: '16px 16px 12px',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '8px',
        border: `1px solid ${palette.divider}`,
        width: '200px',
        position: 'relative'
    },

    flowerItemImage: {
        width: '100%',
        height: 128,
        border: `1px solid ${palette.divider}`,
        marginBottom: 12,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    flowerItemName: {
        fontSize: typography.caption.fontSize
    },
    flowerItemQty: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: `${palette.divider}`,
        borderRadius: '5px 0px 0px 0px',
        padding: '2px 5px',
        fontWeight: 'bold'
    },

    iconContainer: {
        textAlign: 'center',
        padding: '10px 0'
    },
    strikeout: {
        textDecoration: 'line-through'
    },
    warning: {
        color: palette.validation.suggested
    },
    good: {
        color: palette.validation.good
    }
});

const Tabs = [
    {
        id: 'MortuaryItem',
        label: 'Mortuary Item',
        component: compose(withRouter, withSnackbarMessage, withStyles(styles))(MortuaryItem),
        fragment: MortuaryItemFragment,
        onLoad: data => {
            flattenBookingItems(data.Disposal, 'DisposalBookingItems');
            data.Florists.forEach(florist => flattenBookingItems(florist, 'BookingItems'));
        },
        formatSaveData: (saveData, state) => {}
    }
];

class MortuaryItemView extends Component {
    render() {
        return (
            <DataFormView
                tabs={Tabs}
                createNew={false}
                createNewFunc={null}
                objectType="Funeral"
                itemId={this.props.match.params.id}
                name="MortuaryItem"
                readOnly={true}
            />
        );
    }
}

export default withErrorBoundary(compose(withRouter, withStyles(styles))(MortuaryItemView), {
    fallback: ErrorFallback
});
