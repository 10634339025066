import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import DataTable from '../../component/dataTable/DataTable';
import { refereeDoctorListColumns, refereeDoctorListFragment } from './OnlineOrdersConstants';
import GatedComponent from '../../component/GatedComponent';
import { withTheme } from '@material-ui/core/styles';
import { getMyClient } from '../../apollo';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';

class OnlineOrders extends Component {
    state = {};

    render() {
        const { classes } = this.props;
        return (
            <div className="referee-doctor-list">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <span>Online Orders</span>
                        </Typography>
                        <br />
                    </Grid>
                </Grid>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.wideScroll}>
                        <GatedComponent showComponentCode={'FM_ACCESS_Enquiries'}>
                            {() => this.renderRefereeDoctorTable()}
                        </GatedComponent>
                    </div>
                </Paper>
            </div>
        );
    }

    renderRefereeDoctorTable() {
        return (
            <Fragment>
                <Grid item>
                    <Grid container spacing={24}>
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <DataTable
                                customClient={getMyClient}
                                queryName="readOnlineOrders"
                                fragment={refereeDoctorListFragment}
                                columns={refereeDoctorListColumns}
                                variables={{
                                    limit: 10,
                                    filterBy: [],
                                    filters: [],
                                    sortBy: [
                                        {
                                            field: 'OrderDate',
                                            direction: 'DESC'
                                        }
                                    ]
                                }}
                                brandProperty="LegacyKey"
                                searchVariable="contains"
                                tableTitle="Orders for review"
                                isSortable
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const styles = ({ spacing, typography, palette, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    wideScroll: {
        overflow: 'visible',
        overflowY: 'auto',
        padding: 12,
        paddingTop: 0,
        paddingLeft: 24,
        marginLeft: -12
    },
    tableData: {
        marginTop: '1rem'
    },
    inline: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    dotList: {
        minHeight: 16,
        width: 16,
        display: 'inline-block',
        position: 'absolute',
        left: -18,
        margin: '0 -10px 0 0',
        padding: '0',
        listStyle: 'none',
        '& > :not(:first-child)': {
            marginTop: 2
        }
    },
    dot: {
        height: 16,
        width: 16,
        borderRadius: 16
    },
    icon: {
        fontSize: 16,
        // color: '#26CC6F',
        height: '30px',
        width: '30px'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        '& tbody tr:hover': {
            cursor: 'pointer',
            outline: '3px solid black'
        },
        '& tbody td': {
            padding: 8
        }
    }
});

export default withErrorBoundary(compose(withSnackbarMessage, withTheme(), withStyles(styles))(OnlineOrders), {
    fallback: ErrorFallback
});
