import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import TextField from '../../../component/form/TextField';
import RadioGroup from '../../../component/form/RadioGroup';
import LinkButton from '../../../component/form/LinkButton';
import { AltButton, SaveButton } from '../../../component/form/PrimaryButton';
import {
    InlineField,
    InlineFieldRightAlignChildren,
    InlineHeader,
    InlineHeaderRightAlignChildren
} from '../../../component/form/Inline';
import ColumnLayout from '../../../component/ColumnLayout';
import Table, { TableCell, TableHeaderCell, TableHeaderRow, TableRow } from '../../../component/form/Table';
import { REGISTRATION_STATUS_OPTIONS, TabFieldMapping } from './BdmsConstants';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { applyUrlParams, stringIsNullOrEmpty } from '../../../util/strings';
import { stringToDate } from '../../../util/date';
import { submitToLifeLink, testSubmitToLifeLink } from './LifeLink';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import moment from 'moment';
import Checkbox from '../../../component/form/Checkbox';
import Label from '../../../component/form/Label';
import WebServiceLogModal from './WebServiceLogModal';
import GatedComponent from '../../../component/GatedComponent';
import { isNullOrUndefined } from '../../../util/objects';
import Spinner from '../../../component/Spinner';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import { IS_LIVE } from '../../../util/sessions';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import cloneDeep from 'lodash.clonedeep';
import FileUpload, { getFileName } from '../../../component/form/FileUpload';
import DocumentModal from '../billing/documentModal/DocumentModal';
import { CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '../../../component/icon/EditIcon';
import SaveIcon from '../../../component/icon/SaveIcon';
import { getClient } from '../../../apollo';
import { UpdateFileMutation } from './BdmsFragment.js';
import CloseIcon from '../../../component/icon/CloseIcon';

class Bdms extends Component {
    state = {
        logsModal: { open: false, log: null },
        documentModal: { open: false, document: null },
        sendingToLifeLink: false,
        filenameIsBeingEdited: -1,
        filenameIsBeingSaved: false,
        newFilename: false
    };

    render() {
        const { logsModal, documentModal } = this.state;
        const { form } = this.props;
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
                <WebServiceLogModal
                    onClose={() => this.setState({ logsModal: { open: false, log: null } })}
                    open={logsModal.open}
                    log={logsModal.log}
                />
                <DocumentModal
                    onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                    open={!!documentModal.open}
                    form={form}
                    document={documentModal.document}
                />
            </Fragment>
        );
    }

    renderFirstColumn() {
        const {
            form,
            classes,
            match: {
                params: { id }
            },
            locked
        } = this.props;

        const ExportStatus = form.getField('ExportStatus') || '(not registered)';
        const ExportId = form.getField('ExportId') || '(not registered)';
        const DeathCertificateStatus = form.getField('DeathCertificateStatus') || '(not registered)';
        const DeathCertificateNumber = form.getField('DeathCertificateNumber') || '(not registered)';
        const ExportDate = form.getField('ExportDate') || null;
        const certificateRequired = form.getField('Application.CertificateRequired') || null;
        const assumedDone = ['FD Import', 'Successful', 'Submitted'].indexOf(ExportStatus) > -1;
        const hasBeenSubmittedSuccessfully = this.hasBeenSubmittedSuccessfully();
        const tabsWithIssues = this.getTabsWithIssues();
        const recordStatus = form.getField('FuneralDataStatus');
        const dataStatus =
            recordStatus === 4 || assumedDone || hasBeenSubmittedSuccessfully
                ? 4 // marked as complete
                : tabsWithIssues.length === 0
                ? convertFuneralDataStatus(2, form.getField('Disposal.Date')) // no issues, could be ready or hold
                : 1; // not ready

        const readOnly = dataStatus === 4 || locked;

        return (
            <Grid container spacing={16}>
                <Grid container spacing={16} style={{ margin: 0, padding: 8 }}>
                    <InlineHeader header="NSW Births Deaths and Marriages Registry:" />
                </Grid>
                <Grid bucket={true}>
                    <InlineHeaderRightAlignChildren header={'Registration (via LifeLink)'}>
                        <RadioGroup disabled={true} options={REGISTRATION_STATUS_OPTIONS} value={dataStatus} />
                    </InlineHeaderRightAlignChildren>

                    <InlineField>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Date Sent"
                                value={(ExportDate && moment(ExportDate).format('LL')) || 'Not sent'}
                                disabled={true}
                            />
                        </Grid>
                    </InlineField>

                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <TableHeaderRow>
                                <TableHeaderCell>Registration Status</TableHeaderCell>
                                <TableHeaderCell>Registration ID</TableHeaderCell>
                            </TableHeaderRow>
                            <TableRow pad={true}>
                                <TableCell className={classes.halfShell}>
                                    <div>{ExportStatus}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{ExportId}</div>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Grid>
                </Grid>

                <Grid bucket={true}>
                    <Grid item xs={12}>
                        <InlineHeader header={'Death Certificate'}>
                            <Checkbox
                                name="Application.CertificateRequired"
                                label="Is required"
                                form={form}
                                falseValue={false}
                                disabled={!!readOnly}
                            />
                            <Checkbox
                                name="DeathCertificateNotRequired"
                                label="Not required"
                                form={form}
                                falseValue={false}
                                disabled={!!readOnly}
                            />
                        </InlineHeader>
                    </Grid>

                    {certificateRequired && (
                        <Grid item xs={6}>
                            <Label text={'Number of Certificates'} />
                            <TextField
                                type="number"
                                inputProps={{ min: '0' }}
                                name="Application.Quantity"
                                form={form}
                                disabled={!!readOnly}
                            />
                        </Grid>
                    )}

                    {(certificateRequired || DeathCertificateStatus !== '(not registered)') && (
                        <Grid item xs={12}>
                            <Table className={classes.table}>
                                <TableHeaderRow>
                                    <TableHeaderCell>Death Certificate Status</TableHeaderCell>
                                    <TableHeaderCell>Death Certificate Number</TableHeaderCell>
                                </TableHeaderRow>
                                <TableRow pad={true}>
                                    <TableCell className={classes.halfShell}>
                                        <div>{DeathCertificateStatus}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{DeathCertificateNumber}</div>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    )}
                </Grid>
                <Grid bucket>
                    <Query query={ReadAllDocuments} variables={{ id }}>
                        {results => this.renderLegacyDocuments(results)}
                    </Query>
                </Grid>
            </Grid>
        );
    }

    renderLoading(message) {
        const { classes } = this.props;
        return (
            <span>
                <Spinner className={classes.spinner} />
                {message}
            </span>
        );
    }

    renderSecondColumn() {
        const { classes, form } = this.props;

        if (isNullOrUndefined(form) || form.loading) {
            return this.renderLoading('Loading LifeLink data...');
        }

        const { sendingToLifeLink } = this.state;
        const ExportStatus = form.getField('ExportStatus') || '';
        const assumedDone = ['FD Import', 'Successful', 'Submitted'].indexOf(ExportStatus) > -1;
        const hasBeenSubmittedSuccessfully = this.hasBeenSubmittedSuccessfully();
        const tabsWithIssues = this.getTabsWithIssues();
        const recordStatus = form.getField('FuneralDataStatus');
        const dataStatus =
            recordStatus === 4 || assumedDone || hasBeenSubmittedSuccessfully
                ? 4 // marked as complete
                : tabsWithIssues.length === 0
                ? convertFuneralDataStatus(2, form.getField('Disposal.Date')) // no issues, could be ready or hold
                : 1; // not ready

        let miniMessageType, miniMessageContent;
        if (hasBeenSubmittedSuccessfully) {
            miniMessageType = 'good';
            miniMessageContent =
                ExportStatus === 'Compliant'
                    ? 'The record was successfully submitted to LifeLink.'
                    : ExportStatus === 'Non Compliant'
                    ? 'The record was successfully submitted to LifeLink but was non compliant. ' +
                      'BDM staff will proceed with the registration or contact you for clarification.'
                    : ExportStatus === 'Complete'
                    ? 'The record was successfully submitted and passed various internal checks and ' +
                      'the life event is registered.'
                    : 'The record was registered with LifeLink.';
        } else {
            if (assumedDone) {
                miniMessageType = 'good';
                miniMessageContent = 'This record is previously registered with BDMs.';
            } else if (!!form && !!form.isDirty) {
                miniMessageType = 'warning';
                miniMessageContent = 'Please save the record before you submit to LifeLink.';
            } else if (tabsWithIssues.length > 0) {
                miniMessageType = 'error';
                miniMessageContent = 'The items below need to be amended before you can submit to LifeLink.';
            } else if (dataStatus === 2) {
                miniMessageType = 'warning';
                miniMessageContent =
                    'This record is on hold - disposal must occur before it can be submitted to LifeLink.';
            } else if (dataStatus < 2) {
                miniMessageType = 'error';
                miniMessageContent = 'This record is not ready to be submitted to LifeLink.';
            } else {
                miniMessageType = 'info';
                miniMessageContent = 'This record is ready to be submitted to LifeLink.';
            }
        }

        const disabled = (!!form && !!form.isDirty) || !!sendingToLifeLink;

        return (
            <Fragment>
                <Grid container spacing={16} style={{ margin: 0, padding: 8 }}>
                    <Grid item xs={12}>
                        <BiggerMessageBar messageType={miniMessageType}>{miniMessageContent}</BiggerMessageBar>
                    </Grid>
                    {!(hasBeenSubmittedSuccessfully || assumedDone) && !!tabsWithIssues && tabsWithIssues.length > 0 && (
                        <Grid item xs={12}>
                            {!hasBeenSubmittedSuccessfully &&
                                tabsWithIssues.map((tabIssues, tabIndex) => (
                                    <Fragment key={tabIndex}>
                                        <InlineHeader header={tabIssues.tab} />
                                        <ol className={classes.issueList}>
                                            {tabIssues.issues.map((issue, issueIndex) => (
                                                <li key={issueIndex}>
                                                    <InlineFieldRightAlignChildren>
                                                        <span>{issue.description}</span>
                                                        <LinkButton
                                                            text="Make Change"
                                                            onClick={() => this.goToIssue(issue.link)}
                                                        />
                                                    </InlineFieldRightAlignChildren>
                                                </li>
                                            ))}
                                        </ol>
                                    </Fragment>
                                ))}
                        </Grid>
                    )}
                    {!(hasBeenSubmittedSuccessfully || assumedDone) && tabsWithIssues.length === 0 && dataStatus > 2 && (
                        <Grid item xs={12}>
                            <GatedComponent showComponentCode={'FM_ACCESS_Lifelink'}>
                                {() => {
                                    return (
                                        <SaveButton
                                            disabled={disabled || !IS_LIVE}
                                            onClick={() => this.submitToLifeLink()}
                                        >
                                            <TickCircleIcon />
                                            Submit to LifeLink
                                        </SaveButton>
                                    );
                                }}
                            </GatedComponent>
                            <GatedComponent showComponentCode={'FM_ACCESS_TestLifelink'}>
                                {() => {
                                    return (
                                        <div style={{ float: 'right' }}>
                                            <AltButton disabled={disabled} onClick={() => this.testSubmitToLifeLink()}>
                                                TEST Submit to LifeLink
                                            </AltButton>
                                        </div>
                                    );
                                }}
                            </GatedComponent>
                        </Grid>
                    )}

                    {sendingToLifeLink && (
                        <Grid item xs={12}>
                            {this.renderLoading('Submitting to Life Link...')}
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={16} style={{ margin: 0, padding: 8 }}>
                    {this.renderWebServiceLogs()}
                </Grid>
            </Fragment>
        );
    }

    getTabsWithIssues() {
        const { form } = this.props;
        const lifeLinkValidationFields = form.getField('LifeLinkValidationField');
        if (!lifeLinkValidationFields || !lifeLinkValidationFields.AllItems) return [];

        const tabIssues = {};
        for (let x = 0; x < lifeLinkValidationFields.AllItems.length; x++) {
            const { Key, Value } = lifeLinkValidationFields.AllItems[x];
            if (Key === 'success' && x === 0) continue;

            const tab = TabFieldMapping[Key];
            if (tabIssues[tab.label] === undefined) tabIssues[tab.label] = { tab: tab.title || tab.label, issues: [] };

            tabIssues[tab.label].issues.push({ description: Value, link: tab.link });
        }

        return Object.keys(tabIssues).map(k => tabIssues[k]);
    }

    hasBeenSubmittedSuccessfully() {
        const { form } = this.props;

        //legacy record
        const lifeLinkValidationFields = form.getField('BdmResponse');
        if (
            !isNullOrUndefined(lifeLinkValidationFields) &&
            !isNullOrUndefined(lifeLinkValidationFields.AllItems) &&
            lifeLinkValidationFields.AllItems.length > 0 &&
            !stringIsNullOrEmpty(lifeLinkValidationFields.AllItems[0].Value)
        ) {
            return lifeLinkValidationFields.AllItems[0].Value.trim() === '200';
        }

        //current record
        const exportStatus = form.getField('ExportStatus') || '';
        return exportStatus === 'Complete' || exportStatus === 'Compliant' || exportStatus === 'Non Compliant';
    }

    goToIssue(link) {
        const { match, history } = this.props;
        const path = applyUrlParams(link, match.params);
        history.push(path);
    }

    submitToLifeLink() {
        this.executeSubmitToLifeLinkQuery(false);
    }

    testSubmitToLifeLink() {
        this.executeSubmitToLifeLinkQuery(true);
    }

    executeSubmitToLifeLinkQuery(test) {
        const { form } = this.props;
        this.setState({ sendingToLifeLink: true });

        const submitFunc = test ? testSubmitToLifeLink : submitToLifeLink;

        submitFunc(form.getField('ID')).then(
            data => {
                if (
                    isNullOrUndefined(data) ||
                    isNullOrUndefined(data.readFunerals) ||
                    data.readFunerals.edges.length === 0
                ) {
                    this.onLifeLinkError('Unexpected response from LifeLink. Reload and try again?', data);
                    return;
                }

                const {
                    WebServiceLogs,
                    ExportStatus,
                    ExportId,
                    DeathCertificateStatus,
                    DeathCertificateNumber,
                    ExportDate
                } = data.readFunerals.edges[0].node;

                test
                    ? form.setField({ WebServiceLogs }, false)
                    : form.setField(
                          {
                              WebServiceLogs,
                              ExportStatus,
                              ExportId,
                              DeathCertificateStatus,
                              DeathCertificateNumber,
                              ExportDate
                          },
                          false
                      );

                this.setState({ sendingToLifeLink: false });
            },
            e => this.onLifeLinkError('Failed to submit to LifeLink.', e)
        );
    }

    onLifeLinkError(msg, obj) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        this.props.setSnackbarMessage(msg);
        console.error(errorMessage, obj);
        this.setState({ sendingToLifeLink: false });
    }

    renderWebServiceLogs() {
        const { form } = this.props;

        let webServiceLogs = form.getField('WebServiceLogs') || [];

        const bdmResponse = form.getField('FormattedBDMResponse');

        if (!stringIsNullOrEmpty(bdmResponse)) {
            webServiceLogs = [
                {
                    ID: 'N/A',
                    TestMode: false,
                    Request: null,
                    Created: form.getField('ExportDate') || 'N/A',
                    Response: bdmResponse
                }
            ].concat(webServiceLogs);
        }

        return (
            <Fragment>
                <Grid item xs={12}>
                    <Label>LifeLink Responses</Label>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ maxHeight: 550, overflowX: 'auto', width: '100%' }}>
                        {webServiceLogs.length > 0 ? (
                            <Table columns={['ID', 'Created', 'Result', 'Type']}>
                                {webServiceLogs
                                    .map(log => {
                                        return {
                                            ...log,
                                            Result: log.Response
                                                ? log.Response.search(/<\/faultcode>/) === -1
                                                    ? 'Success'
                                                    : log.Response.match(/>([\w\s.:;-]+)<\/faultstring>/)[1]
                                                : 'Failure'
                                        };
                                    })
                                    .map((log, i) => (
                                        <TableRow key={i} pad={true}>
                                            <TableCell>
                                                <LinkButton
                                                    onClick={() =>
                                                        this.setState({
                                                            logsModal: { open: true, log }
                                                        })
                                                    }
                                                    text={log.ID}
                                                />
                                            </TableCell>
                                            <TableCell>{log.Created}</TableCell>
                                            <TableCell>{log.Result}</TableCell>
                                            <TableCell>{log.TestMode ? 'Test only' : 'Live'}</TableCell>
                                        </TableRow>
                                    ))
                                    .reverse()}
                            </Table>
                        ) : (
                            <i>No logs</i>
                        )}
                    </div>
                </Grid>
            </Fragment>
        );
    }

    renderLegacyDocuments(results) {
        const { data, loading, error } = results;
        const {
            form,
            classes,
            match: {
                params: { id }
            }
        } = this.props;
        const { readOneFuneral } = data || false;
        const { Enquiry } = readOneFuneral || false;
        const { Prearrangement } = Enquiry || false;
        const funeralDocuments = form.getField('LegacyDocuments') || [];
        const enquiryDocuments = (Enquiry && Enquiry.LegacyDocuments) || [];
        const preneedDocuments = (Prearrangement && Prearrangement.LegacyDocuments) || [];
        const legacyDocuments = []
            .concat(
                cloneDeep(funeralDocuments).map(e => {
                    e.Source = 'Funeral';
                    return e;
                }),
                enquiryDocuments.map(e => {
                    e.Source = 'Enquiry';
                    return e;
                }),
                preneedDocuments.map(e => {
                    e.Source = 'Prearrangement';
                    return e;
                })
            )
            .sort((a, b) => (Number(a.ID) > Number(b.ID) ? -1 : 1));
        return (
            <Grid container spacing={16}>
                <Grid bucket>
                    <Grid item xs={12}>
                        <Label>Legacy Documents</Label>
                    </Grid>
                    <Grid item xs={12}>
                        {loading && (
                            <BiggerMessageBar messageType={'loading'}>Loading associated documents...</BiggerMessageBar>
                        )}
                        {error && (
                            <BiggerMessageBar messageType={'warning'}>
                                Unable to load associated documents.
                            </BiggerMessageBar>
                        )}
                        {legacyDocuments.length > 0 ? (
                            <Table columns={['Filename', 'Size', 'From']}>
                                {legacyDocuments.map((obj, i) => (
                                    <TableRow key={i} pad={true}>
                                        <TableCell>
                                            {(this.state.filenameIsBeingEdited !== i && (
                                                <Fragment>
                                                    <LinkButton
                                                        onClick={() =>
                                                            this.setState({
                                                                documentModal: { open: true, document: obj }
                                                            })
                                                        }
                                                        text={obj.Name}
                                                    />
                                                    <IconButton
                                                        title="Edit Filename"
                                                        onClick={() => {
                                                            this.setState({
                                                                filenameIsBeingEdited: i,
                                                                newFilename: obj.Name
                                                            });
                                                        }}
                                                        disabled={this.state.filenameIsBeingSaved}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Fragment>
                                            )) || (
                                                <InlineField>
                                                    <TextField
                                                        value={this.state.newFilename}
                                                        onChange={e => {
                                                            this.setState({ newFilename: e.target.value });
                                                        }}
                                                    />

                                                    {this.state.filenameIsBeingSaved ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <Fragment>
                                                            <IconButton
                                                                title="Confirm new filename"
                                                                className={classes.confirmation}
                                                                onClick={() => {
                                                                    this.setState({ filenameIsBeingSaved: true });
                                                                    getClient()
                                                                        .mutate({
                                                                            mutation: UpdateFileMutation,
                                                                            variables: {
                                                                                input: {
                                                                                    ID: obj.ID,
                                                                                    Name: this.state.newFilename
                                                                                }
                                                                            },
                                                                            refetchQueries: [
                                                                                {
                                                                                    query: ReadAllDocuments,
                                                                                    variables: { id }
                                                                                }
                                                                            ],
                                                                            awaitRefetchQueries: true
                                                                        })
                                                                        .then(() => {
                                                                            form.doRefetch(() => {
                                                                                this.setState({
                                                                                    filenameIsBeingSaved: false,
                                                                                    filenameIsBeingEdited: -1
                                                                                });
                                                                            });
                                                                        });
                                                                }}
                                                                disabled={this.state.newFilename === obj.Name}
                                                            >
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                className={classes.cancel}
                                                                title="Cancel edit"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        filenameIsBeingEdited: -1
                                                                    });
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Fragment>
                                                    )}
                                                </InlineField>
                                            )}
                                        </TableCell>
                                        <TableCell>{obj.Size}</TableCell>
                                        <TableCell>
                                            <LinkButton
                                                text={
                                                    obj.Source +
                                                    ' ' +
                                                    ((obj.Source === 'Enquiry' && Enquiry.LegacyKey) ||
                                                        (obj.Source === 'Prearrangement' && Prearrangement.LegacyKey) ||
                                                        form.getField('LegacyKey'))
                                                }
                                                href={
                                                    (obj.Source === 'Prearrangement' &&
                                                        `/prearrangement/${Prearrangement.LegacyKey}/${Prearrangement.ID}`) ||
                                                    (obj.Source === 'Enquiry' &&
                                                        `/quotes/enquiry/${Enquiry.LegacyKey}/${Enquiry.ID}`) ||
                                                    undefined
                                                }
                                                target={'_blank'}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        ) : (
                            <i>No documents</i>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderNewUpload()}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderNewUpload() {
        const { form } = this.props;

        return (
            <div>
                <FileUpload
                    label={'Upload Document'}
                    buttonOnly={true}
                    folderPath={`/documents/funeral/${form.getField('ID')}`}
                    onComplete={({ uploadFile }) => this.onFileUploaded(uploadFile)}
                />
            </div>
        );
    }

    onFileUploaded(upload) {
        const { form } = this.props;
        const LegacyDocuments = form.getField('LegacyDocuments') || [];
        LegacyDocuments.push({
            ID: upload.id,
            AbsoluteLink: upload.AbsoluteLink,
            Name: getFileName(upload.filename),
            Size: 'Save to confirm'
        });
        form.setField({ LegacyDocuments });
    }

    /* not used
    removeUpload(index) {
        const { form } = this.props;
        const LegacyDocuments = form.getField('LegacyDocuments') || [];
        LegacyDocuments.splice(index, 1);
        form.setField({ LegacyDocuments });
    }
    */
}

/**
 * based on the logic from the original app
 * leave 1 or 4 alone
 * otherwise base the status on the current date
 * in future = hold, in past = ready
 */
const convertFuneralDataStatus = (dataStatus, disposalDateString) => {
    if (dataStatus === null || dataStatus === undefined) return 0;

    if (dataStatus === 1 || dataStatus === 4) return dataStatus;

    //no date of disposal, so hold.
    if (!disposalDateString) return 2;

    const disposalDate = stringToDate(disposalDateString);
    const now = new Date();
    return disposalDate > now ? 2 : 3;
};

const ReadAllDocuments = gql`
    query ReadAllDocuments($id: ID!) {
        readOneFuneral(ID: $id) {
            ID
            Enquiry {
                ID
                LegacyKey
                LegacyDocuments {
                    ... on File {
                        ID
                        Name
                        Size
                        AbsoluteLink
                    }
                    ... on Image {
                        ID
                        Name
                        Size
                        AbsoluteLink
                    }
                }
                Prearrangement {
                    ID
                    LegacyKey
                    LegacyDocuments {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
            }
        }
    }
`;

const styles = ({ palette }) => ({
    issueList: {
        margin: '0'
    },
    table: {
        '& tbody > tr > td': {
            borderRadius: '0!important'
        }
    },
    spinner: {
        display: 'inline-block',
        marginRight: '10px'
    },
    halfShell: {
        width: '50%'
    },
    confirmation: {
        color: palette.button.save
    },
    cancel: {
        color: palette.button.destroy
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withSnackbarMessage,
    withStyles(styles)
)(Bdms);
