import React from 'react';
import gql from 'graphql-tag';
import LinkButton from '../../component/form/LinkButton';
import { isRelatedObjectDefined } from '../../util/bookable';
import { joinDefined, prettyPhone, prettyPrice } from '../../util/strings';
import { shortDateTimeFromString } from '../../util/date';
import { CommittalIcon, DownloadIcon, FireIcon, PodiumIcon } from '../../component/IconIndex';

export const refereeDoctorListFragment = gql`
    fragment OnlineOrder on OnlineOrder {
        ID
        LegacyKey
        OrderType
        OrderDate
        CustomerName
        Status
        Phone
        ServiceType
        LocationArea
        PackageName
        AmountPaid
        AmountTotal
        SummaryPdf {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                AbsoluteLink
                Name
                Size
            }
        }
        Funeral {
            ID
            LegacyKey
            NameOfDeceased
            DateOfService
        }
        Prearrangement {
            ID
            LegacyKey
            NameOfDeceased
        }
    }
`;

export const refereeDoctorListFilterByFragment = `
    filterByInfo {
        Status {
            Label
            Options
        }
        AssignedStaff {
            Label
            Options {
               Key
               Value
            }
            FormType
        }
        Brand {
            Label
            Options{
                Key
                Value
            }
        }
    }
`;

export const refereeDoctorListColumns = [
    {
        label: 'Key',
        propertyPath: 'LegacyKey',
        renderCell: ({ Funeral, Prearrangement }, value) =>
            (isRelatedObjectDefined(Funeral) && (
                <LinkButton href={`/funeral/${Funeral.LegacyKey}/${Funeral.ID}/summary`}>{value}</LinkButton>
            )) ||
            (isRelatedObjectDefined(Prearrangement) && (
                <LinkButton href={`/prearrangement/${Prearrangement.LegacyKey}/${Prearrangement.ID}/summary`}>
                    {value}
                </LinkButton>
            ))
    },
    {
        label: 'Deceased Name',
        propertyPath: 'Funeral.Surname',
        renderCell: ({ Funeral, Prearrangement }) =>
            (isRelatedObjectDefined(Funeral) && Funeral.NameOfDeceased) ||
            (isRelatedObjectDefined(Prearrangement) && Prearrangement.NameOfDeceased)
    },
    {
        label: 'Placed',
        propertyPath: 'OrderDate',
        renderCell: ({ OrderDate }) => shortDateTimeFromString(OrderDate)
    },
    {
        label: 'Contact',
        propertyPath: 'CustomerName',
        renderCell: ({ Phone, CustomerName }) => (
            <>
                {CustomerName}
                <br />
                {prettyPhone(Phone, false)}
            </>
        )
    },
    {
        label: 'Service Type',
        propertyPath: 'ServiceType',
        renderCell: ({ ServiceType }) => (
            <div style={{ textAlign: 'center' }}>
                {'Direct Cremation Only' === ServiceType && <FireIcon />}
                {'Service and Cremation' === ServiceType && (
                    <>
                        <PodiumIcon />
                        <FireIcon />
                    </>
                )}
                {'Service and Burial' === ServiceType && (
                    <>
                        <PodiumIcon />
                        <CommittalIcon />
                    </>
                )}
                {'Graveside Burial' === ServiceType && <CommittalIcon />}
                <small>
                    <br />
                    {ServiceType}
                </small>
            </div>
        )
    },
    {
        label: 'Location',
        propertyPath: 'LocationArea'
    },
    {
        label: 'Package',
        propertyPath: 'PackageName'
    },
    {
        label: 'Total Amount',
        propertyPath: 'AmountTotal',
        renderCell: ({ AmountTotal }) => prettyPrice(AmountTotal)
    },
    {
        label: 'Status',
        propertyPath: 'Status'
    },
    {
        label: 'Assigned To',
        renderCell: ({ Funeral }) => joinDefined([''], ', ')
    },
    {
        label: 'Summary',
        propertyPath: 'SummaryPdf.AbsoluteLink',
        renderCell: ({ SummaryPdf }) => {
            return (
                isRelatedObjectDefined(SummaryPdf) && (
                    <LinkButton href={SummaryPdf.AbsoluteLink} target="blank" text={<DownloadIcon />} />
                )
            );
        }
    }
];

export const readOnlineOrdersBtnQuery = gql`
    query OnlineOrderQuery($key: String) {
        readOnlineOrders(key: $key) {
            edges {
                node {
                    ID
                    SummaryPdf {
                        ... on File {
                            ID
                            Name
                            AbsoluteLink
                        }
                    }
                    OrderData
                }
            }
        }
    }
`;
