import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import gql from 'graphql-tag';
import GraphqlGrid from '../../component/form/v2/GraphqlGrid';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';
import { prettyPrice } from '../../util/strings';
import { shortDateFromString } from '../../util/date';
import LinkButton from '../../component/form/LinkButton';
import InvoiceModal from '../funeral/billing/invoiceModal/InvoiceModal';
import { createForm } from '../../util/form';
import { getClient } from '../../apollo';
import { Invoice } from '../fragments/Invoices';
import moment from 'moment';
import BrandDot from '../../component/table/BrandDot';
import { getOfficeFromKey } from '../../util/brands';
import { XERO_STATUS } from './FinancialConstants';

class Invoices extends Component {
    listQuery = gql`
        query invoicesQuery($limit: Int, $offset: Int, $filter: InvoiceFilterFields, $sort: InvoiceSortFields) {
            readInvoices(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                edges {
                    node {
                        ID
                        Created
                        LastEdited
                        XeroID
                        XeroStatus
                        XeroReference
                        LegacyKey
                        Hena
                        IsPurchase
                        Total
                        InvoiceDate
                        InvoicePaid
                        InvoiceSent
                        InvoiceDueDate
                        FuneralID
                    }
                }
                pageInfo {
                    totalCount
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    `;

    itemQuery = gql`
        ${Invoice}
        query readOneInvoiceQuery($id: ID!) {
            readOneInvoice(ID: $id) {
                ...InvoiceObject
            }
        }
    `;

    state = {
        invoiceID: null
    };

    form = createForm(this, { Invoices: [] });

    render() {
        const { classes } = this.props;
        const { invoiceID } = this.state;

        const listColumns = [
            {
                name: 'Link',
                queryField: 'node.ID',
                hidden: true,
                render: (val, { node }) => `/funeral/${node.Hena}/${val}`
            },
            {
                name: 'InvoiceID',
                title: 'Invoice/Bill ID',
                queryField: 'node.ID',
                gqlFilterOperation: 'eq',
                gqlFilterField: 'ID',
                gqlSortField: 'Created',
                noWrap: true,
                hidden: false,
                render: val => <>#{val}</>
            },
            {
                name: 'Funeral Key',
                queryField: 'node.Hena',
                gqlFilterField: 'Hena',
                gqlSortField: 'Hena',
                noWrap: true,
                render: (val, { node }) => {
                    const { color } = getOfficeFromKey(val);
                    return (
                        !!val && (
                            <LinkButton href={node.FuneralID > 0 ? `/funeral/${val}/${node.FuneralID}` : null}>
                                <BrandDot dotColour={color} />
                                <span style={{ marginLeft: 8 }}>{val}</span>
                            </LinkButton>
                        )
                    );
                }
            },
            {
                name: 'Xero Ref',
                queryField: 'node.XeroReference',
                gqlFilterField: 'XeroReference',
                gqlSortField: 'XeroReference',
                noWrap: true
            },
            {
                name: 'Status',
                queryField: 'node.XeroStatus',
                gqlFilterField: 'XeroStatus',
                gqlSortField: 'XeroStatus',
                noWrap: true,
                gqlFilterOptions: XERO_STATUS.map(status => ({ label: status, value: status }))
            },
            {
                name: 'Due Date',
                queryField: 'node.InvoiceDueDate',
                gqlFilterField: 'InvoiceDueDate',
                gqlSortField: 'InvoiceDueDate',
                noWrap: true,
                render: (val, { node }) => (
                    <span
                        className={
                            !node.InvoicePaid &&
                            'AUTHORISED' === node.XeroStatus &&
                            moment(val)
                                .endOf('day')
                                .isBefore()
                                ? classes.isBad
                                : null
                        }
                    >
                        {shortDateFromString(val)}
                    </span>
                )
            },
            {
                name: 'Amount',
                queryField: 'node.Total',
                gqlSortField: 'Total',
                gqlFilterField: 'Total',
                gqlFilterOperation: 'eq',
                gqlFilterNumber: true,
                noWrap: true,
                render: val => prettyPrice(val)
            },
            {
                name: 'Cashflow',
                queryField: 'node.IsPurchase',
                gqlSortField: 'IsPurchase',
                gqlFilterField: 'IsPurchase',
                gqlFilterOptions: [
                    { label: 'Bills', value: true },
                    { label: 'Invoices', value: false }
                ],
                gqlFilterOperation: 'eq',
                noWrap: true,
                render: (val, { node }) => (node.IsPurchase ? 'Bill' : 'Invoice')
            }
        ];

        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <span>Financial</span>
                    <span className={classes.header}>Invoices & Bills</span>
                </Typography>
                <br />
                <Paper className={classes.paper} elevation={0}>
                    <div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <GraphqlGrid
                                    hideSearch
                                    columns={listColumns}
                                    noResultsMessage={
                                        <Typography>
                                            No items matched your search.
                                            <br />
                                            <small>Try different keywords or filters.</small>
                                        </Typography>
                                    }
                                    gqlReadQuery={this.listQuery}
                                    gqlQueryVariables={{
                                        sort: { Created: 'DESC' }
                                    }}
                                    gqlDefaultMode
                                    gqlReadDataSelector={'readInvoices.edges'}
                                    gqlReadPageInfoSelector={'readInvoices.pageInfo'}
                                    gqlPageSizes={[5, 10, 25, 50]}
                                    onRowClick={(evt, { node }) => {
                                        evt.preventDefault();
                                        getClient()
                                            .query({
                                                query: this.itemQuery,
                                                variables: { id: node.ID }
                                            })
                                            .then(({ data }) => {
                                                if (data && data.readOneInvoice) {
                                                    this.form.setState({ Invoices: [data.readOneInvoice] });
                                                    this.setState({ invoiceID: data.readOneInvoice.ID });
                                                }
                                            });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>

                <InvoiceModal
                    onClose={() => this.setState({ invoiceID: null })}
                    open={!!invoiceID}
                    //onSubmit={e => this.onSubmitNewInvoice(e)}
                    form={this.form}
                    invoiceIndex={0}
                />
            </Fragment>
        );
    }
}

const styles = ({ spacing, breakpoints, palette, funeralHome, typography }) => ({
    paper: {
        padding: `${spacing.unit * 2}px ${spacing.unit * 5}px`,
        [breakpoints.down('md')]: {
            padding: spacing.unit * 2
        }
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.action.active,
        color: palette.text.primary
    },
    isGood: {
        color: palette.validation.good
    },
    isBad: {
        color: palette.validation.error
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(Invoices), {
    fallback: ErrorFallback
});
