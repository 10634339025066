import React, { Component, Fragment } from 'react';
import { compose, Query } from 'react-apollo/index';
import gql from 'graphql-tag';
import { AltButton, OutlineButton } from '../component/form/PrimaryButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { getUser } from '../util/sessions';
import { applyUrlParams, stringIsNullOrEmpty } from '../util/strings';
import { isNullOrUndefined } from '../util/objects';
import { InlineFieldRightAlignChildren } from '../component/form/Inline';
import TaskCard from './workQueue/components/TaskCard';
import TaskCardContainer from './workQueue/components/TaskCardContainer';
import { createOrUpdateTaskFunc, getDashboardTasksFunc } from './workQueue/components/GetSaveTasks';
import ViewTaskModal from './workQueue2/Modals/ViewTaskModal';
import CreateEditTaskModal from './workQueue/taskModal/CreateEditTaskModal';
import { withSnackbarMessage } from '../context/SnackbarMessage';
import cloneDeep from 'lodash.clonedeep';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkButton from '../component/form/LinkButton';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden/Hidden';
import { getPats } from './quotes/QuotesConstants';
import { getCalendarClient, getMyClient } from '../apollo';
import GatedComponent from '../component/GatedComponent';
import { ExpansionPanelDetails } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DashboardTeamFilters from '../component/DashboardTeamFilters';
import Inline, { inlineAlignment } from './workQueue2/extras/Inline';
import { GQL_REFETCH_30S, GQL_REFETCH_60S, subscribe } from '../util/subscriptions.js';
import BrandDot from '../component/table/BrandDot';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../component/ErrorFallback';
import AppointmentPill from './calendar/AppointmentPill';

class Dashboard extends Component {
    state = {
        tasks: { edges: [] },
        funeralTasks: [],
        showCreateEditTaskModal: false,
        loading: false,
        loadingTaskId: null,
        view: 'day',
        filterStaff: [],
        filterTypes: [],
        filterGroups: [],
        staff: [],
        taskStatistics: {
            completedThisWeek: 0,
            newThisWeek: 0,
            overdue: 0,
            outstanding: 0
        },
        user: getUser()
    };

    constructor(props) {
        super(props);
        this.staffQueryRef = React.createRef();
        this.assignmentsQueryRef = React.createRef();
        subscribe(this.staffQueryRef, GQL_REFETCH_30S);
        subscribe(this.assignmentsQueryRef, GQL_REFETCH_60S);
    }

    componentDidMount() {
        const { user } = this.state;
        this.loadTasks(user, true);
    }

    render() {
        const { user, modalTask, editTask, showCreateEditTaskModal, loading, loadingTaskId } = this.state;
        const { classes } = this.props;
        const createNewTaskButton =
            user.FirstName === 'cheese' ? (
                <OutlineButton onClick={() => this.setState({ showCreateEditTaskModal: true })}>
                    + Create New Task
                </OutlineButton>
            ) : null;

        return (
            <Fragment>
                <ViewTaskModal
                    onClose={() => this.setState({ modalTask: undefined })}
                    open={!loading && !!modalTask}
                    task={modalTask}
                    onSubmit={(task, mutate) => this.viewTaskOnSubmit(task, mutate)}
                    onEdit={task => this.viewTaskOnEdit(task)}
                    onClickAction={() => this.goToTask(modalTask)}
                />
                <CreateEditTaskModal
                    onClose={() => this.setState({ showCreateEditTaskModal: false })}
                    open={!loading && showCreateEditTaskModal}
                    task={editTask}
                    onSubmit={task => this.createOrUpdateTask(task)}
                    user={user}
                />
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>G'day {user.FirstName}, welcome to Funeral Manager.</span>
                        {createNewTaskButton}
                    </InlineFieldRightAlignChildren>
                </Typography>

                <Fragment>
                    {this.renderStaffSchedule()}
                    {this.renderSummary()}
                    {this.renderTopPriorityTasks()}
                    {this.renderFuneralTasks()}
                    {this.renderTeamFuneralTasks()}
                </Fragment>
            </Fragment>
        );
    }

    renderSummary() {
        const { taskStatistics, loading, loadingTaskId } = this.state;
        if (loading && loadingTaskId === null) return null;
        return (
            <Fragment>
                <div>
                    <div>
                        <p>
                            {taskStatistics.completedThisWeek > 0 ? (
                                <Fragment>
                                    You've completed {taskStatistics.completedThisWeek} task
                                    {taskStatistics.completedThisWeek === 1 ? '' : 's'} this week!{' '}
                                </Fragment>
                            ) : (
                                <Fragment>You haven't completed any tasks this week. </Fragment>
                            )}
                            {getPats(taskStatistics.completedThisWeek)}
                        </p>
                        <p>
                            {taskStatistics.outstanding > 0 ? (
                                <Fragment>
                                    You have {taskStatistics.outstanding}
                                    {taskStatistics.outstanding.length === 1 ? ' task' : ' tasks'} outstanding for this
                                    week.
                                </Fragment>
                            ) : (
                                <Fragment> You have no outstanding tasks for this week.</Fragment>
                            )}
                            {taskStatistics.newThisWeek > 0 && (
                                <Fragment>
                                    {' '}
                                    You have been assigned {taskStatistics.newThisWeek} new task
                                    {taskStatistics.newThisWeek === 1 ? '' : 's'} this week.
                                </Fragment>
                            )}
                            {taskStatistics.overdue > 0 && (
                                <Fragment>
                                    {' '}
                                    You have {taskStatistics.overdue} task{taskStatistics.overdue === 1 ? '' : 's'}{' '}
                                    overdue.
                                </Fragment>
                            )}
                        </p>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderStaffSchedule() {
        const { classes } = this.props;
        const { user } = this.state;

        const date = moment(new Date());
        const myCalendarUrl = `/calendar/${date.format('YYYYMMDD')}`;
        const staffQueryVariables = {
            filterStaff: [user.ID],
            from: moment(date, 'YYYYMMDD').startOf('day'),
            to: moment(date, 'YYYYMMDD').endOf('day')
        };

        return (
            <div className={classes.section}>
                <div className={classes.right}>
                    <LinkButton text="View Your Calendar" onClick={() => this.props.history.push(myCalendarUrl)} />
                </div>
                <Typography variant="title" align="left" className={classes.paperTitle} gutterBottom>
                    Your Day Ahead:
                </Typography>
                <div className={classes.appointmentContainer}>
                    <Query
                        query={staffQuery}
                        fetchPolicy="cache-and-network"
                        client={getCalendarClient()}
                        variables={staffQueryVariables}
                        ref={this.staffQueryRef}
                    >
                        {results => this.renderAppointments(results)}
                    </Query>
                </div>
            </div>
        );
    }

    renderAppointments({ loading, error, data }) {
        if (loading) {
            return <CircularProgress />;
        }

        if (!stringIsNullOrEmpty(error)) {
            return error;
        }

        if (isNullOrUndefined(data) || isNullOrUndefined(data.readStaffAllocations)) {
            return 'Failed to load appointments';
        }

        const appointments = data.readStaffAllocations;

        return (
            <Fragment>
                {appointments.length > 0 ? (
                    appointments
                        .sort((a, b) => (a.Start < b.Start ? -1 : 1))
                        .map((allocation, idx) => <AppointmentPill key={idx} allocation={allocation} />)
                ) : (
                    <div style={{ marginLeft: '0.5rem' }}>You currently have no appointments for today.</div>
                )}
            </Fragment>
        );
    }

    renderTopPriorityTasks() {
        const { classes } = this.props;
        const { tasks, loading, loadingTaskId } = this.state;
        const outstandingTasks = tasks.edges
            .filter(task => !task.node.Completed)
            .sort((a, b) => {
                // put overdue dates first
                if (!a.node.Due) return 1;
                if (!b.node.Due) return -1;
                return a.node.Due < b.node.Due ? -1 : 1;
            })
            .sort((a, b) => {
                // new to old
                if (!!a.node.Due) return 0;
                if (!!b.node.Due) return 0;
                return a.node.Created > b.node.Created ? -1 : 1;
            })
            .slice(0, 12);
        return (
            <div className={classes.section}>
                <div style={{ float: 'right' }}>
                    <LinkButton text={'View All Tasks'} onClick={() => this.props.history.push('/work-queue-cards')} />
                </div>
                <Typography variant="title" align="left" className={classes.paperTitle} gutterBottom>
                    Your Top Priority Tasks:
                </Typography>
                {loading && loadingTaskId === null ? (
                    <span className={classes.loading}>
                        <CircularProgress />
                    </span>
                ) : outstandingTasks.length > 0 ? (
                    <div className={classes.funeralTasksContainer}>
                        <TaskCardContainer>
                            {outstandingTasks.map((task, index) => (
                                <TaskCard
                                    key={index}
                                    task={task}
                                    onClickAction={() => this.goToTask(task)}
                                    onClickShowModal={() => this.showTaskModal(task)}
                                    loading={loading}
                                    loadingTaskId={loadingTaskId}
                                    onChange={t => this.createOrUpdateTask(t)}
                                />
                            ))}
                        </TaskCardContainer>
                    </div>
                ) : (
                    <Fragment>You have no top priority tasks.</Fragment>
                )}
            </div>
        );
    }

    renderFuneralTasks() {
        const { classes } = this.props;
        return (
            <GatedComponent isEnabledCode={'OBJ_EDIT_ALL'}>
                {(edit, data) => {
                    const { ID } = data.readCurrentUser;
                    return (
                        <div className={classes.section}>
                            <Typography variant="title" align="left" className={classes.paperTitle} gutterBottom>
                                Your Assigned Funerals:
                            </Typography>
                            <div className={classes.funeralsAssignedContainer}>
                                <Paper className={classes.paper} elevation={0}>
                                    {this.renderFuneralTaskList([ID])}
                                </Paper>
                            </div>
                        </div>
                    );
                }}
            </GatedComponent>
        );
    }

    renderTeamFuneralTasks() {
        const { classes } = this.props;
        const { filterGroups } = this.state;
        return (
            <GatedComponent isEnabledCode={'OBJ_EDIT_ALL'}>
                {(edit, data) => {
                    const { Groups } = data.readCurrentUser;
                    const manager = Groups.find(
                        e =>
                            [
                                'admin-staff',
                                'admin-staff-managers',
                                'customer-service-manager',
                                'operation-managers',
                                'funeral-directors',
                                'funeral-arrangers',
                                'administrators',
                                'irx-staff'
                            ].indexOf(e.Code) > -1
                    );
                    if (!!manager) {
                        // need all my users...
                        const filters = {
                            Code: {
                                in: [
                                    'irx-staff', // we'll ditch these people
                                    'funeral-arrangers',
                                    'customer-service-manager',
                                    'admin-staff'
                                ]
                            }
                        };
                        return (
                            <div className={classes.section}>
                                <Typography variant="title" align="left" className={classes.paperTitle} gutterBottom>
                                    Your Team's Assigned Funerals:{' '}
                                    <small style={{ fontWeight: 200 }}>
                                        A list of funerals where Arrangers & Conductors have an upcoming service, or
                                        where Coordinators have an incomplete checklist, BDM or invoice.
                                    </small>
                                </Typography>
                                <div className={classes.funeralsAssignedContainer}>
                                    <Paper className={classes.paper} elevation={0}>
                                        <Query
                                            query={teamsQuery}
                                            fetchPolicy="cache-and-network"
                                            variables={{ filters }}
                                            client={getCalendarClient()}
                                        >
                                            {({ data, loading, error }) => {
                                                if (error)
                                                    return "Oops, there was an error. Let's try this again later.";
                                                if (loading && Object.keys(data).length === 0)
                                                    return <CircularProgress />;
                                                const hitched = [];
                                                const ditched = [];
                                                if (data && data.readGroups && data.readGroups.length) {
                                                    const popupFilterGroups = data.readGroups.filter(
                                                        e =>
                                                            [
                                                                'customer-service-manager',
                                                                'funeral-arrangers',
                                                                'admin-staff'
                                                            ].indexOf(e.Code) > -1
                                                    );
                                                    data.readGroups.forEach(e => {
                                                        if (
                                                            e.Code === 'irx-staff' ||
                                                            (filterGroups &&
                                                                filterGroups.length > 0 &&
                                                                filterGroups.filter(g => g.Code === e.Code).length ===
                                                                    0)
                                                        ) {
                                                            e.Members.forEach(m => {
                                                                ditched.push(m.ID);
                                                            });
                                                        } else {
                                                            e.Members.forEach(m => {
                                                                if (!hitched.find(p => p.ID === m.ID))
                                                                    hitched.push({ ...m, team: e.Title });
                                                            });
                                                        }
                                                    });
                                                    const people = hitched.filter(h => ditched.indexOf(h.ID) < 0);
                                                    return this.renderFuneralTaskList(
                                                        people.map(e => e.ID),
                                                        people.sort((a, b) => (a.Surname < b.Surname ? -1 : 1)),
                                                        popupFilterGroups
                                                    );
                                                }
                                                return 'No team members found.';
                                            }}
                                        </Query>
                                    </Paper>
                                </div>
                            </div>
                        );
                    }
                    // just need me...
                    return null;
                }}
            </GatedComponent>
        );
    }

    renderFuneralTaskList(userIDs, people, popupFilterGroups = []) {
        const { classes } = this.props;
        const { updateExpand } = this.state;

        if (updateExpand !== undefined && people && people.length > 0) {
            this.state.updateExpand = undefined;
            this.state.randomPostfix = Math.floor(Math.random() * 8999 + 1000);
        }
        const renderedPersonCounter = { count: 0 };
        return (
            <Query
                query={assignmentsQuery}
                fetchPolicy="cache-and-network"
                client={getMyClient()}
                variables={{ memberIDs: userIDs }}
                ref={this.assignmentsQueryRef}
            >
                {result => {
                    if (result.loading && (!result.data || Object.keys(result.data).length === 0))
                        return <CircularProgress />;

                    // check for paginated lookups...
                    const { fetchMore } = result;
                    if (result.data && Object.keys(result.data).length !== 0) {
                        const onLoadMore = () => {
                            const groupings = Object.keys(result.data);

                            // get the length of the longest accumulated result list, use that for paginated lookup
                            const maxOffset = Math.max(
                                ...groupings.map(grouping => result.data[grouping].edges.length)
                            );
                            fetchMore({
                                variables: {
                                    offset: maxOffset
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return previousResult;
                                    const newResult = cloneDeep({ ...fetchMoreResult });
                                    groupings.forEach(
                                        grouping =>
                                            (newResult[grouping].edges = [
                                                ...previousResult[grouping].edges,
                                                ...fetchMoreResult[grouping].edges
                                            ])
                                    );
                                    return newResult;
                                }
                            });
                        };

                        if (
                            result.data.readAssignedArrangers.pageInfo.hasNextPage ||
                            result.data.readAssignedConductors.pageInfo.hasNextPage ||
                            result.data.readAssignedCoordinators.pageInfo.hasNextPage
                        ) {
                            onLoadMore();
                        }
                    }

                    if (people === undefined) return this.renderFuneralTasksResult(result);
                    return (
                        <Fragment>
                            <Inline
                                alignment={inlineAlignment.rightAlignSiblings}
                                center
                                className={classes.dashboardInline}
                            >
                                <div>
                                    <DashboardTeamFilters
                                        setFilters={this.setFilters}
                                        filters={{
                                            staff: this.state.filterStaff || [],
                                            types: this.state.filterTypes || [],
                                            groups: this.state.filterGroups || []
                                        }}
                                        resources={people}
                                        groups={popupFilterGroups}
                                    />
                                </div>
                                <Inline>
                                    <AltButton onClick={() => this.setState({ updateExpand: 'expand' })}>
                                        + <Hidden xsDown> Expand All</Hidden>
                                    </AltButton>
                                    <AltButton onClick={() => this.setState({ updateExpand: 'collapse' })}>
                                        - <Hidden xsDown> Collapse All</Hidden>
                                    </AltButton>
                                </Inline>
                            </Inline>
                            {people.map(person =>
                                this.renderFuneralTasksResult(result, person, updateExpand, renderedPersonCounter)
                            )}
                            {renderedPersonCounter.count === 0 && <div>There are no matching records</div>}
                        </Fragment>
                    );
                }}
            </Query>
        );
    }

    setFilters = ({ staff, types, groups }) => {
        const myID = getUser().ID;
        const excludeMe = staff.filter(e => Number(e) !== Number(myID));
        this.setState(
            {
                filterStaff: !!excludeMe && excludeMe.length > 0 ? [myID].concat(excludeMe) : [],
                filterTypes: types,
                filterGroups: groups,
                offset: 0
            },
            () => this.refetch && this.refetch()
        );
    };

    renderFuneralTasksResult(
        { loading, error, data },
        person = null,
        updateExpand,
        renderedPersonCounter = { count: 0 }
    ) {
        const { classes } = this.props;
        const { filterTypes, filterStaff } = this.state;

        if (error) return "Oops, there was an error. Let's try this again later.";
        const filterFuneralsByType = filterTypes.length > 0 && !!person;
        const skipPerson = filterStaff.length > 0 && person && filterStaff.indexOf(person.ID) === -1;

        if (skipPerson) return '';

        const funeralIDs = [];

        const conductorTasks =
            !filterFuneralsByType || filterTypes.indexOf('Funeral Conductor') > -1
                ? ((data && data.readAssignedConductors && data.readAssignedConductors.edges) || [])
                      .filter(e => !!e && !!e.node.Funeral && e.node.Funeral.ID !== '0')
                      .filter(e => (person ? person.ID === e.node.Member.ID : true))
                      .map(e => {
                          funeralIDs.push(e.node.Funeral.ID);
                          const thing = { ...e.node };
                          thing.Role = 'Conductor';
                          return thing;
                      })
                : false;
        const arrangerTasks =
            !filterFuneralsByType || filterTypes.indexOf('Arranger') > -1
                ? ((data && data.readAssignedArrangers && data.readAssignedArrangers.edges) || [])
                      .filter(
                          e =>
                              !!e &&
                              !!e.node.Funeral &&
                              e.node.Funeral.ID !== '0' &&
                              funeralIDs.indexOf(e.node.Funeral.ID) < 0
                      )
                      .filter(e => (person ? person.ID === e.node.Member.ID : true))
                      .map(e => {
                          funeralIDs.push(e.node.Funeral.ID);
                          const thing = { ...e.node };
                          thing.Role = 'Arranger';
                          return thing;
                      })
                : false;
        const coordinatorTasks =
            !filterFuneralsByType || filterTypes.indexOf('Coordinator') > -1
                ? ((data && data.readAssignedCoordinators && data.readAssignedCoordinators.edges) || [])
                      .filter(
                          e =>
                              !!e &&
                              !!e.node.Funeral &&
                              e.node.Funeral.ID !== '0' &&
                              funeralIDs.indexOf(e.node.Funeral.ID) < 0
                      )
                      .filter(e => (person ? person.ID === e.node.Member.ID : true))
                      .map(e => {
                          funeralIDs.push(e.node.Funeral.ID);
                          const thing = { ...e.node };
                          thing.Role = 'Coordinator';
                          return thing;
                      })
                : false;
        const funeralTasks = [];

        if (arrangerTasks) funeralTasks.push(...arrangerTasks);
        if (coordinatorTasks) funeralTasks.push(...coordinatorTasks);
        if (conductorTasks) funeralTasks.push(...conductorTasks);

        // sort by date of service (oldest first)
        const sortedTasks = funeralTasks.sort((a, b) => {
            if (a.Funeral.DateOfService < b.Funeral.DateOfService) return -1;
            if (a.Funeral.DateOfService > b.Funeral.DateOfService) return 1;
            if (a.Funeral.AdminChecksComplete < b.Funeral.AdminChecksComplete) return -1;
            if (a.Funeral.AdminChecksComplete > b.Funeral.AdminChecksComplete) return 1;
            return 0;
        });

        // remove duplicates from the person's list (where they have multiple roles on the same funeral.)
        const funIDs = [];
        const filteredTasks = sortedTasks.filter(node => {
            if (funIDs.indexOf(node.Funeral.ID) > -1) {
                return false;
            } else {
                funIDs.push(node.Funeral.ID);
                return true;
            }
        });

        const renderResult = (
            <Fragment>
                {(filteredTasks.length > 0 && (
                    <div className={classes.tableBucket}>
                        <table className={classes.funeralTasksTable}>
                            <thead>
                                <tr>
                                    <th>Office</th>
                                    <th>Funeral</th>
                                    <Hidden smDown={true}>
                                        <th>
                                            <Hidden mdDown={true}>Deceased </Hidden>Name
                                        </th>
                                    </Hidden>
                                    <th>
                                        <Hidden mdDown={true}>Date of </Hidden>Service
                                    </th>
                                    <th>
                                        <Hidden mdDown={true}>Suppliers </Hidden>Confirmed
                                    </th>
                                    <th>Checklist</th>
                                    <th>
                                        <Hidden mdDown={true}>Outstanding </Hidden>Tasks
                                    </th>
                                    <Hidden smDown={true}>
                                        <th>
                                            BDM <Hidden mdDown={true}>Sent </Hidden>
                                        </th>
                                        <th>
                                            Invoice <Hidden mdDown={true}>Raised </Hidden>
                                        </th>
                                        <th>
                                            Assigned <Hidden mdDown={true}>As </Hidden>
                                        </th>
                                    </Hidden>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTasks &&
                                    filteredTasks.map(task => {
                                        const funeral = task.Funeral;
                                        const letterCode = funeral.LegacyKey.slice(-1);
                                        const typeColor = this.props.theme.palette.contentForeground[letterCode];
                                        const outstandingTasksCount = funeral.getOutstandingTasksCount;
                                        const percentComplete = Math.round(funeral.AdminChecksComplete * 100);
                                        const exported = funeral.ExportDate
                                            ? moment(funeral.ExportDate).format('ddd ll')
                                            : 'Unsent';
                                        const invoiced = funeral.getInvoicedDate
                                            ? moment(funeral.getInvoicedDate).format('ddd ll')
                                            : 'Missing';
                                        return (
                                            <tr key={funeral.ID} onClick={() => this.goToFuneralTasks(funeral)}>
                                                <td>
                                                    <span className={classes.dotContainer}>
                                                        <BrandDot dotColour={typeColor} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <LinkButton
                                                        href={`/funeral/${funeral.LegacyKey}/${funeral.ID}/summary`}
                                                        target="_blank"
                                                        text={funeral.LegacyKey}
                                                    />
                                                </td>
                                                <Hidden smDown={true}>
                                                    <td>{funeral.NameOfDeceased.trim() || '(unknown)'}</td>
                                                </Hidden>
                                                <td>
                                                    {(!!funeral.DateOfService &&
                                                        moment(funeral.DateOfService).format('ddd ll')) || (
                                                        <i>(to be advised)</i>
                                                    )}
                                                </td>
                                                <td>{funeral.Confirmed ? 'Yes' : 'No'}</td>
                                                <td>{percentComplete}%</td>
                                                <td>{outstandingTasksCount}</td>
                                                <Hidden smDown={true}>
                                                    <td>{exported}</td>
                                                    <td>{invoiced}</td>
                                                    <td>{task.Role}</td>
                                                </Hidden>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                )) ||
                    'There are currently no funerals assigned to this person.'}
            </Fragment>
        );
        if (person) {
            if (filterFuneralsByType && funeralIDs.length === 0) return '';
            renderedPersonCounter.count++;
            return (
                <ExpansionPanel
                    key={person.ID + '_' + this.state.randomPostfix}
                    className={classes.accordion}
                    defaultExpanded={updateExpand === 'expand' ? true : updateExpand === 'collapse' ? false : undefined}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls={'panel-content-' + person.ID}
                        className={classes.accordionButton}
                        classes={{ expanded: classes.accordionButtonOpen }}
                    >
                        <Typography className={classes.heading}>
                            {filteredTasks.length > 0 && <span className={classes.Badge}> {filteredTasks.length}</span>}
                            {person.Surname}, {person.FirstName}
                        </Typography>
                        <Typography className={classes.secondaryHeading}>{person.team}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails id={'panel-content-' + person.ID}>{renderResult}</ExpansionPanelDetails>
                </ExpansionPanel>
            );
        }
        return renderResult;
    }

    getTaskStatistics(tasks, user) {
        const startOfWeek = moment().startOf('week');
        const todayNow = moment();

        let completedThisWeek = 0;
        let newThisWeek = 0;
        let overdue = 0;
        let outstanding = 0;

        for (let x = 0; x < tasks.edges.length; x++) {
            const task = tasks.edges[x].node;

            //find the assigned details
            const assignedMember = task.AssignedMembers.find(e => Number(e.ID) === Number(user.ID));
            if (!assignedMember) continue;

            if (moment(task.Created) >= startOfWeek) {
                //count newly assigned tasks
                newThisWeek++;
            }

            if (!task.Completed) {
                //count all incomplete tasks
                outstanding++;

                //count all overdue tasks
                if (!!task.Due && moment(task.Due) < todayNow) overdue++;
            } else {
                //count tasks completed this week
                if (moment(task.Completed) >= startOfWeek) completedThisWeek++;
            }
        }

        return {
            completedThisWeek,
            newThisWeek,
            overdue,
            outstanding
        };
    }

    loadTasks(user, refresh) {
        this.setState({ loading: true });
        const that = this;
        const filterDate = moment()
            .subtract(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD');
        getDashboardTasksFunc(user.ID, { Created: { gte: filterDate } }, 0, 0, refresh).then(
            originalTasks => {
                const tasks = cloneDeep(originalTasks);
                const taskStatistics = that.getTaskStatistics(tasks, user);
                const trimmedTasks = { edges: tasks.edges.filter(e => !!e) };
                that.setState({ tasks: trimmedTasks, taskStatistics, originalTasks, loading: false });
            },
            error => {
                that.onGqlError('Failed to load tasks.', error);
                that.setState({ loading: false });
            }
        );
    }

    onGqlError(action, error) {
        console.error(action, error);
        this.props.setSnackbarMessage(action, false, null, new Error(error));
    }

    goToTask(task, tabURLSegment) {
        const path = applyUrlParams('/funeral/:key/:id/' + (tabURLSegment || task.node.TabURLSegment), {
            key: task.node.Funeral.LegacyKey,
            id: task.node.Funeral.ID
        });
        this.props.history.push(path);
    }

    goToFuneralTasks(funeral) {
        const path = applyUrlParams('/funeral/:key/:id/summary', {
            key: funeral.LegacyKey,
            id: funeral.ID
        });
        this.props.history.push(path);
    }

    showTaskModal(modalTask) {
        this.setState({ modalTask });
    }

    viewTaskOnSubmit(task, mutate) {
        this.createOrUpdateTask({
            node: {
                ...task.node,
                ...mutate
            }
        });
    }

    viewTaskOnEdit(task) {
        this.setState({
            modalTask: undefined,
            showCreateEditTaskModal: true,
            editTask: task
        });
    }

    createOrUpdateTask(task) {
        const { tasks } = this.state;
        const existing = task.node.ID !== null ? tasks.edges.find(x => x.node.ID === task.node.ID) : null;
        const that = this;
        this.setState({
            loading: true,
            loadingTaskId: existing ? existing.node.ID : null
        });

        createOrUpdateTaskFunc(task, existing).then(
            mutation => {
                let result = null;
                if (mutation.data) {
                    if (mutation.data.updateTask) result = mutation.data.updateTask;
                    else if (mutation.data.createTask) result = mutation.data.createTask;
                    else throw new Error('unexpected task query: ', mutation.data);
                } else {
                    that.onGqlError('Um, server returned no data?');
                    return;
                }

                const dbTask = cloneDeep(result);

                if (existing) {
                    Object.assign(existing.node, dbTask);
                } else if (tasks.edges.length < 5) {
                    //only add if less than 5
                    tasks.edges.push({ node: dbTask });
                }

                that.setState({
                    tasks,
                    editTask: null,
                    showCreateEditTaskModal: false,
                    loading: false,
                    loadingTaskId: null
                });
            },
            error => {
                that.setState({ loading: false, loadingTaskId: null });
                that.onGqlError('Failed to ' + (existing ? 'save' : 'create new') + ' task.', error);
            }
        );
    }
}

const styles = ({ spacing, typography, palette, breakpoints, funeralHome }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    paperTitle: {},
    summaryText: {
        fontWeight: 'lighter',
        fontSize: typography.fontSizes.regular,
        '& > div': {
            lineHeight: '34px'
        }
    },
    right: {
        float: 'right'
    },
    section: {
        margin: '2rem 0 0 0'
    },
    funeralsAssignedContainer: {
        margin: '1rem 0 0',
        borderBottom: '3px solid #35327c'
    },
    funeralTasksContainer: {
        margin: '1rem 0 0',
        [breakpoints.only('md')]: {
            '& > div > :nth-child(n + 10)': {
                display: 'none'
            }
        },
        [breakpoints.only('lg')]: {
            '& > div > :nth-child(n + 9)': {
                display: 'none'
            }
        },
        [breakpoints.up('xxl')]: {
            '& > div > :nth-child(n + 9)': {
                display: 'none'
            }
        }
    },
    tableBucket: {
        width: '100%',
        overflowX: 'auto',
        maxHeight: 525
    },
    funeralTasksTable: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        margin: 0,
        fontSize: '14px',
        fontWeight: 'light',
        width: '100%',
        '& thead > tr > th': {
            textAlign: 'left',
            fontWeight: typography.fontWeightMedium,
            padding: '0 6px 20px 0',
            whiteSpace: 'nowrap',
            background: 'white'
        },
        '& tbody > tr > td': {
            padding: '6px 6px 6px 0'
        },
        '& tbody > tr:hover': {
            background: palette.action.selected,
            cursor: 'pointer'
        }
    },
    dotContainer: {
        paddingLeft: '10px'
    },
    appointmentContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 50%)',
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(auto-fit, 33.3%)'
        },
        [breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, 100%)'
        },
        marginLeft: '-0.5rem',
        marginRight: '-0.5rem'
    },
    accordion: {
        borderBottom: '1px solid #35327c',
        MozBoxShadow: 'none',
        WebkitBoxShadow: 'none',
        '&:last-child': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
        '&:before': {
            opacity: 0
        }
    },
    accordionButton: {
        paddingTop: 4,
        '& > div': {
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        '& > div > span': {
            color: palette.contentForeground[funeralHome]
        }
    },
    accordionButtonOpen: {
        backgroundColor: palette.contentBackground[funeralHome]
    },
    heading: {
        flexBasis: '50%',
        flexShrink: 0,
        marginRight: '0.5em',
        [breakpoints.down('xs')]: {
            flexBasis: '100%',
            paddingBottom: '0.5em'
        }
    },
    secondaryHeading: {
        fontSize: '15px',
        fontWeight: '500',
        color: palette.contentForeground[funeralHome]
    },
    Badge: {
        width: 24,
        height: 24,
        borderRadius: 32,
        backgroundColor: palette.contentForeground[funeralHome],
        color: '#ffffff',
        padding: '3px 8px',
        marginRight: '0.5em'
    },
    dashboardInline: {
        marginBottom: '1rem',
        '&.inline--right': {
            [breakpoints.down('xs')]: {
                justifyContent: 'space-between',
                flexWrap: 'nowrap'
            },
            '& > button': {
                [breakpoints.down('xs')]: {
                    padding: '0.65em 1em'
                }
            }
        },
        '&.inline > :not(:first-child)': {
            [breakpoints.down('xs')]: {
                marginLeft: 0
            }
        }
    }
});

const teamsQuery = gql`
    query($filters: GroupFilterFields!) {
        readGroups(filter: $filters) {
            ID
            Code
            Title
            Members {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const staffQuery = gql`
    query($from: String!, $to: String!, $filterStaff: [ID], $filterTypes: [String]) {
        readStaffAllocations(from: $from, to: $to, filterStaff: $filterStaff, filterTypes: $filterTypes) {
            ID
            Title
            Start
            End
            Type
            Allocation
            LocationFlattened
            Recurring
            Appointment {
                ID
                Reason
                Type
                Members {
                    ID
                    FirstName
                    Surname
                }
            }
            Member {
                ID
                FirstName
                Surname
            }
            Funeral {
                ID
                LegacyKey
                FirstName
                Surname
            }
        }
    }
`;

const assignmentsQuery = gql`
    query ReadMyFunerals($memberIDs: [ID]!, $offset: Int, $limit: Int) {
        readAssignedArrangers(MemberIDs: $memberIDs, offset: $offset, limit: $limit) {
            edges {
                node {
                    ID
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Funeral {
                        ID
                        LegacyKey
                        NameOfDeceased
                        DateOfService
                        AdminChecksComplete
                        Confirmed
                        getOutstandingTasksCount
                        getInvoicedDate
                        ExportDate
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
        readAssignedConductors(MemberIDs: $memberIDs, offset: $offset, limit: $limit) {
            edges {
                node {
                    ID
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Funeral {
                        ID
                        LegacyKey
                        NameOfDeceased
                        DateOfService
                        AdminChecksComplete
                        Confirmed
                        getOutstandingTasksCount
                        getInvoicedDate
                        ExportDate
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
        readAssignedCoordinators(MemberIDs: $memberIDs, offset: $offset, limit: $limit) {
            edges {
                node {
                    ID
                    Member {
                        ID
                        FirstName
                        Surname
                    }
                    Funeral {
                        ID
                        LegacyKey
                        NameOfDeceased
                        DateOfService
                        AdminChecksComplete
                        Confirmed
                        getOutstandingTasksCount
                        getInvoicedDate
                        ExportDate
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withTheme(), withStyles(styles))(Dashboard), {
    fallback: ErrorFallback
});
