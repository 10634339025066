import Certification from './Certification';
import CertificationFragment from './CertificationFragment';
import { isRelatedObjectDefined, sanitizeObjectWithFKs } from '../../../util/bookable';
import { flattenConnection } from '../../../util/functions';
import { isEmailValid, joinValidationResults, messages, validationHelper } from '../../../util/validation';
import {
    allRequiredDocumentFields,
    APPLICANT_RELATIONSHIP_OPTIONS,
    getRequiredDocuments,
    getSignedArrangementFormForDocument
} from './CertificationConstants';
import { joinDefined, stringIsNullOrEmpty, titleCase } from '../../../util/strings';
import { isRelatedObjectUndefined } from '../../../util/graphql';
import { isNullOrUndefined } from '../../../util/objects';

export default {
    label: 'Certification',
    component: Certification,
    fragment: CertificationFragment,
    onLoad: data => {
        flattenConnection(data, 'AuthorisedContacts');
        data.CertificationChangesFromBDM = JSON.parse(data.CertificationChangesFromBDM || '{}');

        // need to reconstruct informant name from legacy data...?
        if (
            !!data.Informant &&
            !!data.Informant.InformantsName &&
            !data.Informant.Surname &&
            !data.Informant.FirstName &&
            !data.Informant.MiddleName
        ) {
            const bits = ('' + data.Informant.InformantsName).split(' ');
            if (!data.Informant.Surname) data.Informant.Surname = bits.pop();
            else bits.pop();
            if (!data.Informant.FirstName) data.Informant.FirstName = bits.shift();
            else bits.shift();
            if (!data.Informant.MiddleName) data.Informant.MiddleName = bits.join(' ');
        }
    },
    formatSaveData: (saveData, state) => {
        const { Certification } = saveData;
        if (Certification && state.Certification.TypeOfBdmDeathCertificate === 'Coroner') {
            saveData.Certification = sanitizeObjectWithFKs(Certification, ['Coroner']);
            delete saveData.Certification.Doctor;
            saveData.Certification.DoctorID = null;
            delete saveData.Certification.Hospital;
            saveData.Certification.HospitalID = null;
            delete saveData.Certification.Referee;
            saveData.Certification.RefereeID = null;
            saveData.DoctorAttachedToFlag = null;
            saveData.RefereeCremationCheck = null;
        } else if (Certification) {
            saveData.Certification = sanitizeObjectWithFKs(Certification, [
                'Referee',
                'Doctor',
                'Hospital',
                'RefereePurchaseOrder',
                'HospitalPurchaseOrder',
                'DoctorPurchaseOrder'
            ]);
            delete saveData.Certification.Coroner;
            saveData.Certification.CoronerID = null;
            saveData.Certification.CoronersCause = null;
        }
        if (!state.DoctorAttachedToFlag && saveData.Certification) {
            delete saveData.Certification.Hospital;
            saveData.Certification.HospitalID = null;
        }

        if (Certification) {
            const requiredDocumentFieldNames = allRequiredDocumentFields
                .map(absoluteFieldName => absoluteFieldName.split('.')[1])
                .filter(fieldName => !isRelatedObjectUndefined(Certification[fieldName]));

            if (requiredDocumentFieldNames.length > 0)
                saveData.Certification = sanitizeObjectWithFKs(Certification, requiredDocumentFieldNames);
        }

        if (saveData.Informant) {
            saveData.Informant.FirstName = titleCase(state.Informant.FirstName);
            saveData.Informant.MiddleName = titleCase(state.Informant.MiddleName);
            saveData.Informant.Surname = titleCase(state.Informant.Surname);
        }
        saveData.CertificationChangesFromBDM =
            Object.keys(saveData.CertificationChangesFromBDM || {}).length === 0
                ? null
                : JSON.stringify(saveData.CertificationChangesFromBDM);
    },

    validation: {
        required: ['Certification.TypeOfBdmDeathCertificate'],
        suggested: ['Informant.Mobile'],
        onValidate: {
            'Informant.FirstName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.FirstName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
            },
            'Informant.MiddleName': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.MiddleName';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return validationHelper.ok();
            },
            'Informant.Surname': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.Surname';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return isNullOrUndefined(persistedValue) ? validationHelper.required() : validationHelper.ok();
            },
            'Informant.Email': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.Email';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (isNullOrUndefined(persistedValue)) return validationHelper.suggested();

                return isEmailValid(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required(messages.invalidEmail);
            },
            'Informant.Mobile': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.Mobile';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return isNullOrUndefined(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },
            'Informant.Phone': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.Phone';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                return isNullOrUndefined(persistedValue) ? validationHelper.suggested() : validationHelper.ok();
            },
            'Informant.RelationshipToDeceased': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const fieldName = 'Informant.RelationshipToDeceased';
                if (changes[fieldName] !== undefined) {
                    return validationHelper.optional('Change value to: ' + (changes[fieldName] || '(empty)'));
                }
                if (!APPLICANT_RELATIONSHIP_OPTIONS.find(opt => opt.value === persistedValue)) {
                    return validationHelper.required(
                        'Value is not valid for LifeLink submission. Please find closest match from the list.'
                    );
                }
                return validationHelper.ok();
            },
            'Informant.ResidentialAddress1': (newValue, persistedValue, hasValue, getField) => {
                const changes = getField('CertificationChangesFromBDM');
                const street = 'Informant.ResidentialAddress1';
                const city = 'Informant.SuburbCityAust';
                const state = 'Informant.ResidentialState';
                const postcode = 'Informant.ResidentialPostcode';
                if (
                    changes[street] !== undefined ||
                    changes[city] !== undefined ||
                    changes[state] !== undefined ||
                    changes[postcode] !== undefined
                ) {
                    return validationHelper.optional(
                        'Change value to: ' +
                            joinDefined([changes[street], changes[city], changes[postcode], changes[state]], ' ')
                    );
                }
                return validationHelper.ok();
            },

            'Informant.DateOfSignature': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Informant.Signed') && !hasValue) return validationHelper.required();

                return validationHelper.ok();
            },

            'Certification.Coroner': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Certification.TypeOfBdmDeathCertificate') !== 'Coroner' || hasValue)
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Certification.Doctor': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Certification.TypeOfBdmDeathCertificate') === 'Coroner' || hasValue)
                    return validationHelper.ok();

                return validationHelper.suggested();
            },

            'Certification.Hospital': (newValue, persistedValue, hasValue, getField) => {
                if (
                    getField('Certification.TypeOfBdmDeathCertificate') === 'Coroner' ||
                    !getField('DoctorAttachedToFlag') ||
                    hasValue
                )
                    return validationHelper.ok();

                return validationHelper.required();
            },

            Certification: (newValue, persistedValue, hasValue, getField) => {
                const requiredDocs = getRequiredDocuments({ getField });
                const validationResults = [];

                for (let x = 0; x < requiredDocs.length; x++) {
                    const requiredFile = getField(requiredDocs[x].fieldName);
                    const arrangementForm = getSignedArrangementFormForDocument(
                        { getField },
                        requiredDocs[x].fieldName,
                        requiredDocs[x].formType
                    );

                    if (
                        (isRelatedObjectUndefined(requiredFile) || stringIsNullOrEmpty(requiredFile.AbsoluteLink)) &&
                        !arrangementForm.PDF
                    ) {
                        validationResults.push(
                            validationHelper.required(messages.missing(requiredDocs[x].description))
                        );
                    } else if (
                        isRelatedObjectDefined(requiredFile.RequiredDocument) &&
                        'Rejected' === requiredFile.RequiredDocument.Status
                    ) {
                        validationResults.push(
                            validationHelper.suggested(requiredDocs[x].description + ' needs replacing')
                        );
                    }
                }

                return joinValidationResults(validationResults);
            }
        }
    }
};
