import gql from 'graphql-tag';
import { capitalize } from '../../util/strings';

export const createTableQuery = (queryName, fragment, dataObject, filterByFragment, isSortable) => {
    const capitalizedQueryName = capitalize(queryName);
    const filterByInput = filterByFragment ? '$filterBy: [Filters]' : '';
    const sortByInput = isSortable ? '$sortBy: [SortField]' : '';
    const filterByInputVar = filterByFragment ? `filterBy: $filterBy` : '';
    const sortByInputVar = isSortable ? `sortBy: $sortBy` : '';
    //const filterByInfo = filterByFragment || '';
    //const objectName = dataObject || queryName.replace(/^(read|update)(.*?)s$/i, '$2');
    const sortByResponse = ''; // isSortable ? getTypeQuery(objectName) : '';

    return gql`
		${fragment}
		query ${capitalizedQueryName}(
            $limit: Int
            $offset: Int
            $contains: String
			$filters: [Filters] 
			${filterByInput}
			${sortByInput}
		){
			${queryName}(
			    limit: $limit
			    offset: $offset 
			    contains: $contains
				filters: $filters
				${filterByInputVar}
				${sortByInputVar}
			){
				edges {
					node {
						ID
						...${fragment.definitions[0].name.value}
					}
				}
				pageInfo {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                }
			}
			${sortByResponse}
		}`;
};

export const getTypeQuery = typeName => {
    return !typeName
        ? ''
        : `
		__type(name:"${typeName}SortFields"){
			name
			inputFields {
				name
				type {
					name
					inputFields {
                        name
                        type {
                            name
                        }
                    }
				}
			}
		}`;
};
